<template>
    <div>
        <NavbarDelivery />
        <main v-if="!loading" style="margin-top: 80px;">
            <router-view></router-view>
        </main>
        <NotificationHandler />
    </div>
</template>

<script>
import NavbarDelivery from '@/components/NavbarDelivery.vue'
import NotificationHandler from '@/components/NotificationHandler.vue'

export default {
    components: {
        NavbarDelivery,
        NotificationHandler
    },
    data: function () {
        return {
            timeout: 0,
            loading: true
        }
    },
    computed: {
        is_authorized_member: function () {
            return this.$store.state.is_authorized_member
        }
    },
    watch: {
        is_authorized_member: async function () {
            if (!this.is_authorized_member) {
                this.$router.replace('/')
            }

            await this.get_member_info()
        }
    },
    created: async function () {
        if (!this.is_authorized_member) {
            this.$router.replace('/')
        }

        await this.get_member_info()
    },
    methods: {
        get_member_info: async function () {
            clearTimeout(this.timeout)
            const self = this
            this.timeout = setTimeout(async function () {
                const response = await self.$store.dispatch('GET_MEMBER_INFO')
                if (response.status === 200) {
                    self.loading = false
                    if (response.data.status === 0) {
                        if (response.data.member.role === 'sales') {
                            return self.$router.replace('/requisition')
                        }
                    }
                } else if (response.status === 401) {
                    const oauth = await self.$store.dispatch('REFRESH_ACCESS_TOKEN')
                    if (oauth.status === 200) {
                        await self.get_member_info()
                        self.loading = false
                    } else {
                        self.loading = false
                        await self.$store.dispatch('LOGOUT_MEMBER')
                        return self.$router.replace('/')
                    }
                }
            }, 50)
        }
    }
}
</script>
