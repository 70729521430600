<template>
    <Modal :close_button="true" :size="'modal-md'" mid="confirm-email-receipt">
        <template v-slot:header>{{$t('requisition.send_receipt_on_email')}}?</template>
        <template v-slot:body>
            <p class="fs-5 text-gray-700">{{$t('requisition.receipt_will_be_send_on')}}</p>
            <p v-if="order && order.customer" class="alert bg-teal-100 fs-5 text-center">{{order.customer.email}}</p>
            <p class="text-gray-500 mb-0 text-center small px-3">{{$t('requisition.operation_might_take_seconds')}}<br>{{$t('requisition.please_be_patient')}}</p>
        </template>
        <template v-slot:footer>
            <button :disabled="loading" type="button" class="btn btn-lg btn-primary bg-gradient px-4" @click="submit()">
                <span class="small">{{$t('requisition.send')}}</span>
            </button>
            <button type="button" class="btn btn-lg px-4" @click="cancel()">
                <span class="small">{{$t('requisition.cancel')}}</span>
            </button>
        </template>
    </Modal>
</template>

<script>
import InventoryOrderStore from '@/common/store/inventory_order.js'

export default {
    props: {
        order: {
            type: Object,
            default: () => {}
        }
    },
    data: function () {
        return {
            loading: false
        }
    },
    created: async function () {
        const self = this
        this.$bus.$off('Modal/hidden/confirm-email-receipt')
        this.$bus.$on('Modal/hidden/confirm-email-receipt', async (value) => {
            self.loading = false
        })
    },
    methods: {
        cancel: async function () {
            this.$bus.$emit('Modal/hide/confirm-email-receipt')
        },
        submit: async function () {
            this.loading = true
            const response = await InventoryOrderStore.dispatch('EMAIL_RECEIPT', { id: this.order.id })
            if (response.data.status === 0) {
                this.$bus.$emit('Notification/show', { title: this.$t('requisition.operation_successful'), msg: this.$t('requisition.order_receipt_has_been_emailed'), level: 'success' })
                this.$bus.$emit('Modal/hide/confirm-email-receipt')
            } else {
                this.$bus.$emit('Notification/show', { title: this.$t('requisition.something_went_wrong'), msg: this.$t('requisition.please_retry_the_operation_or_refresh'), level: 'danger' })
            }
        }
    }
}
</script>
