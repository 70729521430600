import {
    DOMAIN,
    ORDERS_PATH,
    COOKIE_SECURE,
    COOKIE_SELECTED_WORKPLACE,
    COOKIE_SELECTED_WORKPLACE_VALIDITY_S
} from '@/common/constants'

import Cookies from 'js-cookie'
import { createStore } from 'vuex'
import axios from 'axios'

const store = {
    state: {
        location: {},
        categories: [],
        locations: [],
        settings: [],
        items: []
    },
    mutations: {
        SET_INFO (state, value) {
            state.settings = value.settings
            state.locations = value.locations

            let selected_location = Cookies.get(COOKIE_SELECTED_WORKPLACE)
            try {
                selected_location = JSON.parse(selected_location)
                const location = value.locations.find((l) => l.id === selected_location.id)
                if (location) {
                    state.location = location
                }
            } catch {
                Cookies.remove(COOKIE_SELECTED_WORKPLACE, { path: '/', domain: DOMAIN })
            }

            state.items = value.items.sort(function (a, b) {
                if (a.name < b.name) { return -1 }
                if (a.name > b.name) { return 1 }
                return 0
            })
            state.categories = value.categories.sort(function (a, b) {
                if (a.name < b.name) { return -1 }
                if (a.name > b.name) { return 1 }
                return 0
            })
        },
        SET_LOCATION (state, value) {
            state.location = value
            Cookies.set(COOKIE_SELECTED_WORKPLACE, JSON.stringify(value), {
                expires: COOKIE_SELECTED_WORKPLACE_VALIDITY_S / 3600 / 24,
                secure: COOKIE_SECURE,
                domain: DOMAIN,
                path: '/',
                sameSite: 'strict'
            })
        }
    },
    actions: {
        async GET_INFO (context) {
            let result
            try {
                result = await axios.get(`${ORDERS_PATH}/requisition/info`)
                context.commit('SET_INFO', result.data)
            } catch (error) {
                return error.response
            }
            return result
        }
    },
    strict: process.env.NODE_ENV !== 'production'
}

export default createStore(store)
