<template>
    <div class="container">
        <div class="row mt-2 mb-4">
            <div class="col-12 col-md-6">
                <form @submit="submit">
                    <div class="input-group">
                        <input v-model="filters.search" style="min-width: 240px;" type="text" class="form-control fs-5" :placeholder="$t('requisition.search_by_fields')" :aria-label="$t('requisition.search_by_fields')" aria-describedby="button-addon2">
                        <button @click="filters.search = ''" class="btn btn-outline-secondary px-3" type="button" id="button-addon2">X</button>
                    </div>
                </form>
            </div>
        </div>
        <div v-if="loaded" class="row mb-4">
            <div class="col">
                <div v-if="typeof orders[today] === 'undefined'" class="mb-3 text-pear-700 border border-pear-700 bg-pear-200 bg-gradient rounded shadow-sm d-flex justify-content-between py-2 px-3 fw-bolder">{{$t('requisition.no_orders_for_today')}}</div>
                <div v-for="(items, key0) in orders" :key="key0">
                    <div :class="{'text-yellow-700 border-yellow-700 bg-yellow-200': key0 !== today, 'text-pear-700 border-pear-700 bg-pear-200': key0 === today}" class="border bg-gradient rounded shadow-sm d-flex justify-content-between py-2 px-3 mt-2">
                        <div class="fw-bolder">
                            <!-- <BootstrapIcon class="d-inline-block me-3" color="text-yellow-700 fill-yellow-700" name="geo-alt" :size="20" /> -->
                            <span v-if="key0 === today" class="align-middle me-1">{{$t('requisition.today')}},</span>
                            <span class="align-middle">{{format_date_parts(key0)}}</span>
                        </div>
                        <div v-if="items.length === 1">1 {{$t('delivery.order')}}</div>
                        <div v-else>{{items.length}} {{$t('delivery.orders')}}</div>
                    </div>
                    <div v-for="(order, indexO) in items" :key="indexO" class="mt-2">
                        <OrderCard :orderIndex="key0+indexO" :order="order" :filters_search="filters.search" :business_details="business_details" />
                    </div>
                </div>
            </div>
        </div>

        <ModalConfirmEmailReceipt :order="selected_order" />
        <ModalConfirmDiscardOrder :order="selected_order" />
    </div>
</template>

<script>
import OrderCard from '@/common/directive/OrderCard.vue'
// import BootstrapIcon from '@/common/directive/BootstrapIcon'
import RequisitionStore from '@/common/store/requisition.js'
import InventoryOrderStore from '@/common/store/inventory_order.js'
import DateFormat from 'date-fns/format'
import { enUS, ro } from 'date-fns/locale'
import ModalConfirmEmailReceipt from '@/common/modal/modal-confirm_email_receipt.vue'
import ModalConfirmDiscardOrder from '@/common/modal/modal-confirm_discard_order.vue'

export default {
    components: {
        OrderCard,
        // BootstrapIcon,
        ModalConfirmDiscardOrder,
        ModalConfirmEmailReceipt
    },
    data: function () {
        let today = new Date()
        today = today.getFullYear() + '-' + (today.getMonth()+1).toString().padStart(2, '0') + '-' + today.getDate().toString().padStart(2, '0')
        return {
            STATUS_PENDING: 'order_pending',
            STATUS_PREPARING: 'order_preparing',
            STATUS_COMPLETED: 'order_completed',
            STATUS_DELIVERED: 'order_delivered',
            STATUS_DISCARDED: 'order_discarded',
            orders: [],
            filters: {
                search: ''
            },
            today,
            selected_order: null,
            business_details: null,
            loaded: false,
            timeout: 0
        }
    },
    computed: {
        location: function () {
            return RequisitionStore.state.location
        },
        locale: function () {
            return this.$i18n.locale
        }
    },
    watch: {
        filters: {
            deep: true,
            handler: function () {
                clearTimeout(this.timeout)

                const self = this
                this.timeout = setTimeout(async function () {
                    await self.get_calendar()
                }, 250)
            }
        },
        location: async function () {
            await this.get_calendar()
        }
    },
    created: async function () {
        const self = this

        if (this.location && this.location.id) {
            await this.get_calendar()
        }

        this.$bus.$off('Order/email_receipt')
        this.$bus.$on('Order/email_receipt', function (order) {
            self.selected_order = order
            self.$bus.$emit('Modal/show/confirm-email-receipt')
        })

        this.$bus.$off('Order/discard_order')
        this.$bus.$on('Order/discard_order', function (order) {
            self.selected_order = order
            self.$bus.$emit('Modal/show/confirm-order-discard')
        })

        this.$bus.$off('Order/status_handed')
        this.$bus.$on('Order/status_handed', function () {
            self.get_calendar()
        })
        this.$bus.$off('Order/status_discarded')
        this.$bus.$on('Order/status_discarded', function () {
            self.get_calendar()
        })
    },
    methods: {
        submit: async function ($event) {
            $event.preventDefault()
        },
        get_calendar: async function () {
            // const self = this
            const query = {
                location_id: this.location.id
                // from_h: this.filters.from_h,
                // status: Object.keys(this.filters.status).filter(function (key) {
                //     return self.filters.status[key]
                // })
            }
            if (this.filters.search.length >= 2) {
                query.search = this.filters.search
            }
            const response = await InventoryOrderStore.dispatch('GET_CALENDAR', query)
            if (response.data.status === 0) {
                this.orders = response.data.orders
                this.business_details = response.data.business_details
            } else {
                this.$bus.$emit('Notification/show', { title: 'Something Went Wrong', msg: 'Please retry the operation or refresh the page. If the problem persists, please contact us via email.', level: 'danger' })
            }

            this.loaded = true
        },
        format_date_parts: function (str) {
            const parts = str.split('-')
            return DateFormat(new Date(parts[0], parts[1]-1, parts[2]), 'dd MMM yyyy', { locale: this.locale === 'ro' ? ro : enUS })
        }
    }
}
</script>
