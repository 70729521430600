<template>
    <div :class="{'text-red-500': chars > max || chars < min}" class="d-inline-block">{{chars}}/{{max}}</div>
</template>

<script>
export default {
    props: {
        chars: {
            type: Number,
            default: 0
        },
        min: {
            type: Number,
            default: 0
        },
        max: {
            type: Number,
            default: 0
        }
    }
}
</script>
