<template>
    <Modal :close_button="true" :size="'modal-md'" mid="confirm-order-discard">
        <template v-slot:header>{{$t('requisition.confirm_discard_order')}}</template>
        <template v-slot:body>
            <div class="mt-4">
                <div class="mb-3">
                    <label for="discard_reason" class="form-label">{{$t('delivery.discard_reason')}}<span class="ms-1 text-red-500">*</span></label>
                    <CharCounter :chars="form.discard_reason.length" :min="3" :max="200" class="float-end" />
                    <textarea v-model="form.discard_reason" :required="true" rows="4" class="form-control" id="discard-reason"></textarea>
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <button :disabled="loading || !has_discard_reason" type="button" class="btn btn-lg btn-orange-300 bg-gradient px-4" @click="submit()">
                <span class="small">{{$t('delivery.discard_order')}}</span>
            </button>
            <button type="button" class="btn btn-lg px-4" @click="cancel()">
                <span class="small">{{$t('delivery.go_back')}}</span>
            </button>
        </template>
    </Modal>
</template>

<script>
import InventoryOrderStore from '@/common/store/inventory_order.js'
import CharCounter from '@/common/directive/CharCounter.vue'

export default {
    components: {
        CharCounter
    },
    props: {
        order: {
            type: Object,
            default: () => {}
        }
    },
    data: function () {
        return {
            STATUS_DISCARDED: 'order_discarded',
            loading: false,
            form: {
                order_id: '',
                discard_reason: ''
            }
        }
    },
    watch: {
        order: async function () {
            this.form.order_id = this.order.id
        }
    },
    computed: {
        has_discard_reason: function () {
            return this.form.discard_reason.length >= 10
        }
    },
    created: async function () {
        const self = this
        this.$bus.$off('Modal/hidden/confirm-order-discard')
        this.$bus.$on('Modal/hidden/confirm-order-discard', async (value) => {
            self.loading = false
        })
    },
    methods: {
        cancel: async function () {
            this.$bus.$emit('Modal/hide/confirm-order-discard')
        },
        submit: async function () {
            this.loading = true
            const q = {
                id: this.form.order_id,
                params: {
                    status: this.STATUS_DISCARDED,
                    reason: this.form.discard_reason
                }
            }
            const response = await InventoryOrderStore.dispatch('UPDATE_DELIVERY', q)
            if (response.status === 0) {
                this.$bus.$emit('Modal/hide/confirm-order-discard')
                this.$bus.$emit('Order/status_discarded')
                this.$bus.$emit('Notification/show', { title: 'Operation Successful!', msg: 'The changes have been saved!', level: 'success' })
            } else {
                this.$bus.$emit('Notification/show', { title: 'Something Went Wrong', msg: 'Please retry the operation or refresh the page. If the problem persists, please contact us via email.', level: 'danger' })
            }
            this.form.discard_reason = ''
            this.form.order_id = ''
            this.loading = false
        }
    }
}
</script>
