<template>
    <div class="container">
        <div class="row mb-4">
            <div class="col">
                <div class="bg-white rounded shadow-sm">
                    <div class="row">
                        <div class="col">
                            <div class="d-flex flex-wrap flex-fill">
                                <div @click="filters.status.order_pending = !filters.status.order_pending" class="noselect py-2 px-3">
                                    <BootstrapIcon class="d-inline-block me-2" color="fill-coral-500 text-coral-500" :name="filters.status.order_pending ? 'check-square-fill' : 'square'" :size="18" />
                                    <label class="align-middle">{{$t('delivery.pending')}}</label>
                                </div>
                                <div @click="filters.status.order_preparing = !filters.status.order_preparing" class="noselect py-2 px-3">
                                    <BootstrapIcon class="d-inline-block me-2" color="fill-teal-500 text-green-500" :name="filters.status.order_preparing ? 'check-square-fill' : 'square'" :size="18" />
                                    <label class="align-middle">{{$t('delivery.preparing')}}</label>
                                </div>
                                <div @click="filters.status.order_completed = !filters.status.order_completed" class="noselect py-2 px-3">
                                    <BootstrapIcon class="d-inline-block me-2" color="fill-cyan-500 text-cyan-500" :name="filters.status.order_completed ? 'check-square-fill' : 'square'" :size="18" />
                                    <label class="align-middle">{{$t('delivery.completed')}}</label>
                                </div>
                                <div @click="filters.status.order_discarded = !filters.status.order_discarded" class="noselect py-2 px-3">
                                    <BootstrapIcon class="d-inline-block me-2" color="fill-orange-400 text-orange-400" :name="filters.status.order_discarded ? 'check-square-fill' : 'square'" :size="18" />
                                    <label class="align-middle">{{$t('delivery.discarded')}}</label>
                                </div>
                                <div @click="filters.status.order_delivered = !filters.status.order_delivered" class="noselect py-2 px-3">
                                    <BootstrapIcon class="d-inline-block me-2" color="fill-brown-500 text-brown-500" :name="filters.status.order_delivered ? 'check-square-fill' : 'square'" :size="18" />
                                    <label class="align-middle">{{$t('delivery.handed')}}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-12 col-md-6 col-lg-4">
                            <div class="dropdown me-auto px-3 pb-0 mb-3">
                                <button type="button" class="btn fs-5 btn-outline-secondary w-100 dropdown-toggle fw-bold" data-bs-toggle="dropdown" aria-expanded="false">
                                    <span v-if="filters.from_h === 48">{{$t('requisition.last_hours', { HOURS: 48 })}}</span>
                                    <span v-else-if="filters.from_h === 168">{{$t('requisition.last_days', { DAYS: 7 })}}</span>
                                    <span v-else-if="filters.from_h === 720">{{$t('requisition.last_days', { DAYS: 30 })}}</span>
                                    <span v-else-if="filters.from_h === 2160">{{$t('requisition.last_months', { MONTHS: 3 })}}</span>
                                </button>
                                <ul class="dropdown-menu dropdown-menu-start">
                                    <li v-if="filters.from_h !== 48" @click="filters.from_h = 48" class="p-2 pe-pointer">{{$t('requisition.last_hours', { HOURS: 48 })}}</li>
                                    <li v-if="filters.from_h !== 168" @click="filters.from_h = 168" class="p-2 pe-pointer">{{$t('requisition.last_days', { DAYS: 7 })}}</li>
                                    <li v-if="filters.from_h !== 720" @click="filters.from_h = 720" class="p-2 pe-pointer">{{$t('requisition.last_days', { DAYS: 30 })}}</li>
                                    <li v-if="filters.from_h !== 2160" @click="filters.from_h = 2160" class="p-2 pe-pointer">{{$t('requisition.last_months', { MONTHS: 3 })}}</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <form @submit="submit" class="px-3 w-100 w-sm-50 mb-3">
                                <div class="input-group">
                                    <input v-model="filters.search" style="min-width: 240px;" type="text" class="form-control fs-5" :placeholder="$t('requisition.search_by_fields')" :aria-label="$t('requisition.search_by_fields')" aria-describedby="button-addon2">
                                    <button @click="filters.search = ''" class="btn btn-outline-secondary px-3" type="button" id="button-addon2">X</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="orders.length > 0" class="row">
            <div class="col">
                <div v-for="(order, indexO) in orders" :key="indexO" class="mt-2">
                    <OrderCard :orderIndex="indexO.toString()" :order="order" :filters_search="filters.search" :business_details="business_details" />
                </div>
            </div>
        </div>
        <div v-else class="row">
            <div class="col">
                <EmptyResponse :description="$t('requisition.no_orders_found')" />
            </div>
        </div>

        <ModalConfirmEmailReceipt :order="selected_order" />
        <ModalConfirmDiscardOrder :order="selected_order" />
    </div>
</template>

<script>
import OrderCard from '@/common/directive/OrderCard.vue'
import BootstrapIcon from '@/common/directive/BootstrapIcon'
import InventoryOrderStore from '@/common/store/inventory_order.js'
import RequisitionStore from '@/common/store/requisition.js'
import ModalConfirmEmailReceipt from '@/common/modal/modal-confirm_email_receipt.vue'
import ModalConfirmDiscardOrder from '@/common/modal/modal-confirm_discard_order.vue'
import EmptyResponse from '@/common/directive/EmptyResponse.vue'

export default {
    components: {
        OrderCard,
        EmptyResponse,
        BootstrapIcon,
        ModalConfirmDiscardOrder,
        ModalConfirmEmailReceipt
    },
    data: function () {
        return {
            orders: [],
            selected_order: null,
            business_details: null,
            timeout: 0,
            loading: false,
            filters: {
                search: '',
                status: {
                    order_pending: true,
                    order_preparing: true,
                    order_completed: true,
                    order_discarded: true,
                    order_delivered: true
                },
                from_h: 48
            }
        }
    },
    computed: {
        location: function () {
            return RequisitionStore.state.location
        }
    },
    watch: {
        filters: {
            deep: true,
            handler: function () {
                clearTimeout(this.timeout)

                const self = this
                this.timeout = setTimeout(async function () {
                    await self.get_history()
                }, 250)
            }
        },
        location: async function () {
            await this.get_history()
        }
    },
    created: async function () {
        const self = this

        if (this.location && this.location.id) {
            await this.get_history()
        }

        this.$bus.$off('Order/email_receipt')
        this.$bus.$on('Order/email_receipt', function (order) {
            self.selected_order = order
            self.$bus.$emit('Modal/show/confirm-email-receipt')
        })
        this.$bus.$off('Order/discard_order')
        this.$bus.$on('Order/discard_order', function (order) {
            self.selected_order = order
            self.$bus.$emit('Modal/show/confirm-order-discard')
        })

        this.$bus.$off('Order/status_handed')
        this.$bus.$on('Order/status_handed', function () {
            self.get_history()
        })
        this.$bus.$off('Order/status_discarded')
        this.$bus.$on('Order/status_discarded', function () {
            self.get_history()
        })
    },
    methods: {
        submit: async function ($event) {
            $event.preventDefault()
        },
        get_history: async function () {
            const self = this
            const query = {
                location_id: this.location.id,
                from_h: this.filters.from_h,
                status: Object.keys(this.filters.status).filter(function (key) {
                    return self.filters.status[key]
                })
            }
            if (this.filters.search.length >= 2) {
                query.search = this.filters.search
            }
            const response = await InventoryOrderStore.dispatch('GET_HISTORY', query)
            if (response.status === 200) {
                this.orders = response.data.orders
                this.business_details = response.data.business_details
            } else if (response.status === 422) {
                this.orders = []
                this.$bus.$emit('Notification/show', { title: this.$t('notifications.validation_error'), msg: this.$t('notifications.validation_error_description'), level: 'danger' })
            } else {
                this.orders = []
                this.$bus.$emit('Notification/show', { title: this.$t('notifications.something_went_wrong'), msg: this.$t('notifications.please_retry_the_operation_or_refresh'), level: 'danger' })
            }
        }
    }
}
</script>
