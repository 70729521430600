<template>
    <div v-if="_item && _item.id" class="mb-2 bg-white shadow-sm rounded">
        <div class="rounded-top d-flex justify-content-between align-items-center bg-gray-200 px-3 py-2">
            <div>
                <div class="fs-6 me-2 fw-bolder text-gray-700">{{item.name}}</div>
            </div>
        </div>
        <div class="d-flex justify-content-between align-items-start px-3 py-2">
            <div class="text-center">
                <div class="small mb-1">{{$t('delivery.requested')}}</div>
                <div class="fs-5 py-2">{{item._pivot_quantity}} <span class="small">{{$t('units.unit_abbr_'+item.unit)}}</span></div>
            </div>
            <div class="text-center">
                <div class="small mb-1">{{$t('delivery.prepared')}}</div>
                <div class="input-group flex-nowrap">
                    <button :disabled="['order_completed', 'order_pending', 'order_discarded'].includes(order_status)" @click.stop.prevent="decrement_quantity($event, item)" class="btn btn-outline-secondary px-3" type="button">-</button>
                    <input :disabled="['order_completed', 'order_pending', 'order_discarded'].includes(order_status)" v-model="item._pivot_new_quantity" type="number" class="form-control fs-5" style="width: 60px;">
                    <button :disabled="['order_completed', 'order_pending', 'order_discarded'].includes(order_status)" @click.stop.prevent="increment_quantity($event, item)" class="btn btn-outline-secondary px-3" type="button">+</button>
                </div>
            </div>
            <div class="text-center">
                <div class="small mb-1">{{$t('delivery.processed')}}</div>
                <span @click="['order_preparing'].includes(order_status) && toggle_prepared($event)" class="pe-pointer noselect">
                    <BootstrapIcon v-if="item._pivot_prepared === 0" :class="{'bg-gray-200': ['order_completed', 'order_pending'].includes(order_status)}" color="fill-gray-600 text-gray-600" name="square" :size="38" />
                    <BootstrapIcon v-else :class="{'bg-gray-200': ['order_completed', 'order_pending'].includes(order_status)}" color="fill-cyan-500 text-cyan-500" name="check-square" :size="38" />
                </span>
            </div>
        </div>

        <!-- ITEM DATA FIELDS -->
        <div v-if="item._pivot_data && item._pivot_data.fields && Object.keys(item._pivot_data.fields).length > 0" class="pb-2">
            <div v-for="(set, index) in item._pivot_data.fields" :key="index" class="bg-gray-100 rounded px-3 py-2 mx-2">
                <div v-for="(field, index2) in set" :key="index2">
                    <div v-if="field.value.length > 0">
                        <span class="fw-bolder">{{field.name}}</span>:
                        <span v-if="field.type === 'checkbox'">
                            <span v-if="field.value instanceof Array">{{field.value.join(',')}}</span>
                            <span v-else>{{field.value}}</span>
                        </span>
                        <span v-else>{{field.value}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import InventoryItemStore from '@/common/store/inventory_item.js'
import BootstrapIcon from '@/common/directive/BootstrapIcon.vue'

export default {
    components: {
        BootstrapIcon
    },
    props: {
        order_status: {
            type: String,
            default: ''
        },
        _item: {
            type: Object,
            default: () => {}
        }
    },
    data: function () {
        return {
            item: null,
            timeout: null
        }
    },
    watch: {
        'item._pivot_new_quantity': function (newv, oldv) {
            const self = this
            if (this.item && typeof this.item._pivot_new_quantity === 'number' && typeof oldv === 'number' && oldv !== newv) {
                clearTimeout(this.timeout)
                this.timeout = setTimeout(async function () {
                    self.update_delivery_item()
                }, 1000)
            }
        },
        'item._pivot_prepared': function (newv, oldv) {
            const self = this
            if (this.item && typeof this.item._pivot_prepared === 'number' && typeof oldv === 'number' && oldv !== newv) {
                clearTimeout(this.timeout)
                this.timeout = setTimeout(async function () {
                    self.update_delivery_item()
                }, 1000)
            }
        }
    },
    created: async function () {
        this.item = this._item
    },
    methods: {
        update_delivery_item: async function () {
            const q = {
                id: this.item._pivot_id,
                params: {
                    new_quantity: this.item._pivot_new_quantity,
                    prepared: this.item._pivot_prepared
                }
            }

            await InventoryItemStore.dispatch('UPDATE_DELIVERY_ITEM', q)
        },
        increment_quantity: async function ($event, item) {
            $event.stopPropagation()
            item._pivot_new_quantity++
        },
        decrement_quantity: async function ($event, item) {
            $event.stopPropagation()
            if (item._pivot_new_quantity <= 1) {
                item._pivot_new_quantity = 0
            } else {
                item._pivot_new_quantity--
            }
        },
        toggle_prepared: function ($event) {
            $event.stopPropagation()
            this.item._pivot_prepared = this.item._pivot_prepared === 0 ? 1 : 0
        }
    }
}
</script>
