<template>
    <div>
        <NavbarPublicStore />
        <main>
            <router-view />
        </main>
    </div>
</template>

<script>
import NavbarPublicStore from '@/components/NavbarPublicStore.vue'

export default {
    components: {
        NavbarPublicStore
    }
}
</script>
