import { createStore } from 'vuex'
import Cookies from 'js-cookie'
import axios from 'axios'

import {
    USERS_PATH,
    MEMBERS_PATH,
    WWW_MEMBERS_PATH,
    COOKIE_SECURE,
    COOKIE_LOCALE,
    COOKIE_LOCALE_VALIDITY_S,
    COOKIE_ACCESS_TOKEN_MEMBER,
    COOKIE_ACCESS_TOKEN_MEMBER_VALIDITY_S,
    COOKIE_REFRESH_TOKEN_MEMBER,
    COOKIE_REFRESH_TOKEN_MEMBER_VALIDITY_S,
    COOKIE_CONSENT_VALIDITY_S,
    DOMAIN
} from '@/common/constants'

// import SerializeQuery from '@/common/mixin/SerializeQuery'

export default function make_store (EventBus) {
    // let is_refreshing = false
    // let failed_queue = []
    window.plausible = window.plausible || function () {
        (window.plausible.q = window.plausible.q || []).push(arguments)
    }

    const store = createStore({
        state () {
            return {
                member: {},
                location: {},
                access_token_member: '',
                refresh_token_member: '',
                is_authorized_member: false,
                locale: Cookies.get(COOKIE_LOCALE) || 'en'
            }
        },
        mutations: {
            TRACK_GOAL (state, value) {
                window.plausible(value.goal)
            },
            SET_LOCALE (state, value) {
                state.locale = value
                Cookies.set(COOKIE_LOCALE, value, {
                    expires: COOKIE_LOCALE_VALIDITY_S / 3600 / 24,
                    secure: COOKIE_SECURE,
                    domain: DOMAIN,
                    path: '/',
                    sameSite: 'strict'
                })
            },
            SET_COOKIE_CONSENT (state, value) {
                Cookies.set(value.cookie_name, value.cookie_value, {
                    expires: COOKIE_CONSENT_VALIDITY_S / 3600 / 24,
                    secure: COOKIE_SECURE,
                    domain: DOMAIN,
                    path: '/',
                    sameSite: 'strict'
                })
            },
            UNSET_MEMBER (state, value) {
                state.member = {}
            },
            UNSET_TOKEN_MEMBER (state, value) {
                state.access_token_member = ''
                state.refresh_token_member = ''
                state.is_authorized_member = false
                Cookies.remove(COOKIE_ACCESS_TOKEN_MEMBER, { path: '/', domain: DOMAIN })
                Cookies.remove(COOKIE_REFRESH_TOKEN_MEMBER, { path: '/', domain: DOMAIN })
            },
            INIT_TOKEN_MEMBER (state, value) {
                state.access_token_member = value.access_token
                state.refresh_token_member = value.refresh_token
                state.is_authorized_member = true
                Cookies.set(COOKIE_ACCESS_TOKEN_MEMBER, value.access_token, {
                    expires: COOKIE_ACCESS_TOKEN_MEMBER_VALIDITY_S / 3600 / 24,
                    secure: COOKIE_SECURE,
                    domain: DOMAIN,
                    path: '/',
                    sameSite: 'strict'
                })
                Cookies.set(COOKIE_REFRESH_TOKEN_MEMBER, value.refresh_token, {
                    expires: COOKIE_REFRESH_TOKEN_MEMBER_VALIDITY_S / 3600 / 24,
                    secure: COOKIE_SECURE,
                    domain: DOMAIN,
                    path: '/',
                    sameSite: 'strict'
                })
            },
            SET_MEMBER (state, value) {
                state.member = value.member
            }
        },
        getters: {
        },
        actions: {
            async PUSH_SUBSCRIBE (context, data) {
                let response
                try {
                    response = await axios.post(`${MEMBERS_PATH}/subscribe/delivery`, data.subscription)
                } catch (error) {
                    return error.response
                }
                return response
            },
            async LOGIN_MEMBER (context, data) {
                const headers = {}
                let response = {}
                try {
                    response = await axios.post(`${WWW_MEMBERS_PATH}/login`, { ...data }, { headers })
                    context.commit('INIT_TOKEN_MEMBER', response.data)
                    await context.dispatch('GET_MEMBER_INFO', 'TOKEN_MEMBER')
                } catch (error) {
                    return error.response
                }
                return response
            },
            async LOGOUT_MEMBER (context) {
                const headers = {
                    'x-token': context.state.access_token_member
                }
                let response = {}
                try {
                    response = await axios.post(`${MEMBERS_PATH}/logout`, {}, { headers })
                    context.commit('UNSET_MEMBER')
                    context.commit('UNSET_TOKEN_MEMBER')
                } catch (error) {
                    context.commit('UNSET_MEMBER')
                    context.commit('UNSET_TOKEN_MEMBER')
                    return error.response
                }
                return response.data
            },
            async GET_MEMBER_INFO (context) {
                if (!context.state.access_token_member) {
                    return { status: 8 }
                }
                const headers = {
                    'x-token': context.state.access_token_member
                }
                let response = {}
                try {
                    response = await axios.get(`${MEMBERS_PATH}/info`, { headers })
                    if (response.data.status === 0) {
                        context.commit('SET_MEMBER', response.data)
                    } else {
                        context.commit('UNSET_MEMBER')
                    }
                } catch (error) {
                    context.commit('UNSET_MEMBER')
                    return error.response
                }
                return response
            },
            async REFRESH_ACCESS_TOKEN (context) {
                const headers = {
                    'x-token': context.state.refresh_token_member
                }
                let response = {}
                try {
                    response = await axios.post(`${MEMBERS_PATH}/refresh_access_token`, { }, { headers })
                    context.commit('INIT_TOKEN_MEMBER', response.data)
                } catch (error) {
                    response = error.response
                }
                return response
            },
            async CONTACT (context, data) {
                let result = {}
                try {
                    result = await axios.post(`${USERS_PATH}/contact`, data)
                } catch (error) {
                    return error.response.data
                }
                return result.data
            }
        }
    })

    // const process_queue = (error, token = null) => {
    //     failed_queue.forEach(prom => {
    //         if (error) {
    //             prom.reject(error)
    //         } else {
    //             prom.resolve(token)
    //         }
    //     })

    //     failed_queue = []
    // }

    // axios.defaults.baseURL = WEBSITE_URL

    // Add a request interceptor
    axios.interceptors.request.use(function (config) {
        return new Promise((resolve, reject) => {
            if (config.url.endsWith(`${USERS_PATH}/lookup`) || config.url.endsWith(`${USERS_PATH}/login`)) {
                return resolve(config)
            }

            // get info and logout for members (different scopes)
            if (typeof config.headers['x-token'] === 'string') {
                // do nothing if the token was set in the ACTION
            // inventory location request new token
            } else if (typeof store.state.access_token_member === 'string' && store.state.access_token_member.split('.').length === 3) {
                config.headers['x-token'] = store.state.access_token_member
            }

            resolve(config)
        })
    //     let interval = null
    //     return new Promise((resolve, reject) => {
    //         if (config.url.endsWith(`${USERS_PATH}/register`) || config.url.endsWith(`${USERS_PATH}/login`)) {
    //             return resolve(config)
    //         }
    //         if (store.state.is_authorized && typeof store.state.token === 'string' && store.state.token.split('.').length === 3) {
    //             config.headers['x-token'] = store.state.token
    //             resolve(config)
    //         } else {
    //             interval = setInterval(() => {
    //                 if (store.state.is_authorized && typeof store.state.token === 'string' && store.state.token.split('.').length === 3) {
    //                     clearInterval(interval)
    //                     config.headers['x-token'] = store.state.token
    //                     resolve(config)
    //                 }
    //             }, 200)
    //         }
    //     })
    }, function (error) {
        return Promise.reject(error)
    })

    // Add a response interceptor
    // axios.interceptors.response.use(function (response) {
    //     if (response.config.url.startsWith('api/v1/listings?') ||
    //         response.config.url.startsWith('api/v1/listings/favorites') ||
    //         response.config.url.startsWith('api/v1/users') ||
    //         response.config.url.startsWith('api/v1/searches')) {
    //         EventBus.$emit('PullLoader/disable')
    //     }
    //     return response
    // }, function (error) {
    // //     const original_request = error.config

    // //     // if (error.response.status === 401 && error.config.url.endsWith(TOKENS_PATH)) {
    //     // if (error.response.status === 401) {
    //     //     EventBus.$emit('App/refresh')
    //     // }

    //     if (error.response.status === 401 && !original_request._retry) {
    //         if (is_refreshing) {
    //             return new Promise(function (resolve, reject) {
    //                 failed_queue.push({ resolve, reject })
    //             }).then(token => {
    //                 original_request.headers['x-token'] = token
    //                 return axios(original_request)
    //             }).catch(err => {
    //                 return err
    //             })
    //         }

    //         original_request._retry = true
    //         is_refreshing = true
    //         return new Promise(function (resolve, reject) {
    //             store.dispatch('REGISTER', {}).then((res) => {
    //                 original_request.headers['x-token'] = res.token
    //                 process_queue(null, res.token)
    //                 resolve(axios(original_request))
    //             }).catch((err) => {
    //                 process_queue(err, null)
    //                 reject(err)
    //             }).then(() => {
    //                 is_refreshing = false
    //             })
    //         })
    //     }
    //     return Promise.reject(error)
    // })

    return store
}
