<template>
    <div v-if="!loading" id="content">
        <router-view />
        <PageFooter />
    </div>
</template>

<script>
import PageFooter from '@/components/Footer.vue'
import Cookies from 'js-cookie'
import {
    TLD,
    COOKIE_ACCESS_TOKEN_MEMBER,
    COOKIE_REFRESH_TOKEN_MEMBER,
    COOKIE_LOCALE
} from '@/common/constants'

export default {
    components: {
        PageFooter
    },
    data: function () {
        return {
            loading: true
        }
    },
    created: async function () {
        if (TLD.endsWith('ondigitalocean.app')) {
            document.body.innerHTML = 'oops!'
            return
        }

        // Init locale cookie with ro value
        const _locale = Cookies.get(COOKIE_LOCALE)
        if (!_locale && TLD.endsWith('.ro')) {
            this.$bus.$emit('App/locale', 'ro')
        }

        await this.$router.isReady()

        // check if member token exists
        const _access_token_member = Cookies.get(COOKIE_ACCESS_TOKEN_MEMBER)
        const _refresh_token_member = Cookies.get(COOKIE_REFRESH_TOKEN_MEMBER)

        if (_access_token_member) {
            await this.$store.commit('INIT_TOKEN_MEMBER', {
                access_token: _access_token_member,
                refresh_token: _refresh_token_member
            })
        }

        await this.loaded()
    },
    methods: {
        loaded: async function () {
            this.loading = false
            setTimeout(function () {
                document.body.classList.toggle('loading')
                document.getElementById('app-loader').remove()
            }, 1000)
        }
    }
}
</script>

<style lang="scss">
    @import '@/assets/scss/style.scss';
</style>