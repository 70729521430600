<template>
    <div>
        <div data-bs-toggle="collapse" :href="'#order-'+orderIndex" class="d-block bg-white shadow-sm rounded p-2 text-gray-700 text-decoration-none noselect mb-2">
            <div class="d-flex justify-content-between align-items-center position-relative">
                <div class="mx-2 text-nowrap d-flex">
                    <div v-if="order.status === STATUS_PENDING"><BootstrapIcon color="fill-coral-500 text-coral-500" class="me-3" name="circle-fill" :size="20" /><span class=align-middle>{{$t('delivery.pending')}}</span></div>
                    <div v-if="order.status === STATUS_PREPARING"><BootstrapIcon color="fill-teal-500 text-teal-500" class="me-3" name="circle-fill" :size="20" /><span class=align-middle>{{$t('delivery.preparing')}}</span></div>
                    <div v-if="order.status === STATUS_COMPLETED"><BootstrapIcon color="fill-cyan-500 text-cyan-500" class="me-3" name="circle-fill" :size="20" /><span class=align-middle>{{$t('delivery.completed')}}</span></div>
                    <div v-if="order.status === STATUS_DISCARDED"><BootstrapIcon color="fill-orange-300 text-orange-300" class="me-3" name="circle-fill" :size="20" /><span class=align-middle>{{$t('delivery.discarded')}}</span></div>
                    <div v-if="order.status === STATUS_DELIVERED"><BootstrapIcon color="fill-brown-500 text-brown-500" class="me-3" name="circle-fill" :size="20" /><span class=align-middle>{{$t('delivery.handed')}} / {{format_date(order.updated_at)}}</span></div>
                </div>
                <div class="mx-2 flex-fill text-nowrap text-start" style="min-width: 105px;">
                    <BootstrapIcon color="fill-gray-600 text-gray-600" class="me-2" name="hash" :size="20" />
                    <span class="align-middle">{{order.id.split('-')[0].toUpperCase()}}</span>
                </div>
                <div v-if="order.customer_id" class="mx-2"><BootstrapIcon color="fill-gray-600 text-gray-600" name="person-lines-fill" :size="20" /></div>
                <BootstrapIcon color="fill-gray-600 text-gray-600" name="three-dots" :size="28" class="me-2" />
            </div>
            <div class="text-start d-flex flex-wrap justify-content-between align-items-end">
                <div class="flex-fill">
                    <div class="align-middle text-nowrap">
                        <BootstrapIcon color="fill-blue-500 text-blue-500" class="mx-2" name="cart-plus" :size="20" />
                        <span class="fw-bolder align-middle ms-2">{{order.requisition.username}}</span>
                    </div>
                    <div v-if="order.delivery" class="align-middle text-nowrap">
                        <BootstrapIcon v-if="order.status === STATUS_DISCARDED" color="fill-orange-300 text-orange-300" class="mx-2" name="cart-x" :size="20" />
                        <BootstrapIcon v-else-if="order.status === 'order_preparing'" color="fill-teal-500 text-teal-500" class="mx-2" name="cart" :size="20" />
                        <!-- Should be for order_completed -->
                        <BootstrapIcon v-else color="fill-cyan-500 text-cyan-500" class="mx-2" name="cart-check" :size="20" />
                        <span class="fw-bolder align-middle ms-2">{{order.delivery.username}}</span>
                    </div>
                </div>
                <div class="mx-2"><BootstrapIcon color="fill-gray-600 text-gray-600" name="stack" :size="20" class="me-2" /><span class="align-middle">{{order.items.length}}</span></div>
                <div class="mx-2 text-nowrap" style="min-width: 60px;">
                    <BootstrapIcon color="fill-gray-600 text-gray-600" class="me-2" name="calendar2-plus" :size="20" />
                    <span class="align-middle">{{format_date(order.created_at)}}</span>
                </div>
            </div>
            <div v-if="filters_search.length > 0 && order.customer_id" class="bg-gray-100 rounded mx-2 px-2 py-2 mt-2">
                <span v-if="order.customer.name && order.customer.name.length > 0" class="me-2">
                    <span class="text-nowrap me-2">{{$t('requisition.name')}}:</span>
                    <strong style="width: 99%;">{{order.customer.name}}</strong>
                </span>
                <span v-if="order.customer.phone && order.customer.phone.length > 0" class="me-2">
                    <span class="text-nowrap me-2">{{$t('requisition.phone')}}:</span>
                    <strong style="width: 99%;">{{order.customer.phone}}</strong>
                </span>
                <span v-if="order.customer.email && order.customer.email.length > 0" class="me-2">
                    <span class="text-nowrap me-2">{{$t('requisition.email')}}:</span>
                    <strong style="width: 99%;">{{order.customer.email}}</strong>
                </span>
            </div>
        </div>
        <div class="collapse" :id="'order-'+orderIndex">
            <div class="card card-body border-0 shadow-sm">
                <div v-if="order.status === STATUS_DISCARDED" class="row">
                    <div class="col">
                        <div class="rounded bg-orange-100 border border-orange-300 px-3 py-2 mb-3 mt-2">
                            <p class="m-0"><strong class="me-2">{{$t('requisition.discard_reason')}}:</strong>{{order.reason}}</p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-lg-6 mb-2">
                        <div class="rounded overflow-hidden border">
                            <table class="table table-light small text-gray-700 mb-0">
                                <tbody>
                                    <tr v-for="(item, index1) in order.items" :key="index1">
                                        <td scope="row">{{item.name}}</td>
                                        <td class="text-end">{{item._pivot_quantity}} {{$t('units.unit_abbr_'+item.unit)}}</td>
                                        <td>
                                            <div v-if="item._pivot_data && item._pivot_data.fields" class="rounded border">
                                                <table class="table table-light text-gray-700 mb-0">
                                                    <tbody v-for="(set, index2) in item._pivot_data.fields" :key="index2">
                                                        <tr v-for="(field, index3) in set" :key="index3">
                                                            <td class="text-nowrap">{{field.name}}</td>
                                                            <td v-if="field.type === 'checkbox'">
                                                                <span v-if="field.value instanceof Array">{{field.value.join(',')}}</span>
                                                                <span v-else>{{field.value}}</span>
                                                            </td>
                                                            <td v-else>{{field.value}}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div v-if="order.customer_id || (order.data && Object.keys(order.data).length > 0)" class="col-12 col-lg-6">
                        <div v-if="order.customer_id" class="rounded overflow-hidden border mb-2">
                            <table class="table table-light small text-gray-700 mb-0">
                                <tbody>
                                    <tr v-if="order.customer.name && order.customer.name.length > 0">
                                        <td class="text-nowrap">{{$t('requisition.name')}}</td>
                                        <td style="width: 99%;">{{order.customer.name}}</td>
                                    </tr>
                                    <tr v-if="order.customer.phone && order.customer.phone.length > 0">
                                        <td class="text-nowrap">{{$t('requisition.phone')}}</td>
                                        <td style="width: 99%;">{{order.customer.phone}}</td>
                                    </tr>
                                    <tr v-if="order.customer.email && order.customer.email.length > 0">
                                        <td class="text-nowrap">{{$t('requisition.email')}}</td>
                                        <td style="width: 99%;">{{order.customer.email}}</td>
                                    </tr>
                                    <tr v-if="order.customer.address && order.customer.address.length > 0">
                                        <td class="text-nowrap">{{$t('requisition.address')}}</td>
                                        <td style="width: 99%;">{{order.customer.address}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div v-if="order.data && Object.keys(order.data).length > 0" class="rounded overflow-hidden border">
                            <table class="table table-light small text-gray-700 mb-0">
                                <tbody>
                                    <tr v-for="(value, key) in order.data" :key="key">
                                        <td class="text-nowrap">{{$t('requisition.'+key)}}</td>
                                        <td style="width: 99%;">{{value}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <!-- ACTIONS -->
                <div class="row mt-3">
                    <div class="col text-start">
                        <div class="d-flex gap-3 flex-wrap">
                            <!-- PRINT RECEIPT -->
                            <button @click="print_a8(order)" class="btn btn-lg fs-6 btn-teal-500 bg-gradient text-white" type="button">
                                <BootstrapIcon color="fill-white text-white" class="me-3" name="printer" :size="20" />
                                <span class="align-middle">{{$t('requisition.print_receipt')}}</span>
                            </button>
                            <!-- ORDER DELIVERED -->
                            <button v-if="order.customer_id && order.customer && order.status === STATUS_COMPLETED" :disabled="loading" @click="update_delivery(order.id, STATUS_DELIVERED)" class="btn btn-lg fs-6 btn-brown-500 bg-gradient" type="button">
                                <BootstrapIcon color="fill-gray-100 text-gray-100" class="me-3" name="box-seam" :size="20" />
                                <span class="align-middle">{{$t('delivery.handed_to_customer')}}</span>
                            </button>
                            <!-- EMAIL RECEIPT -->
                            <button v-if="order.customer_id && order.customer && order.customer.email" @click="request_confirm_email_receipt(order)" class="btn btn-lg fs-6 btn-outline-secondary bg-gradient" type="button">
                                <BootstrapIcon color="fill-gray-700 text-gray-700" class="me-3" name="envelope" :size="20" />
                                <span class="align-middle">{{$t('requisition.send_receipt_on_email')}}</span>
                            </button>
                            <!-- EDIT ORDER -->
                            <router-link :to="'/requisition?order_id='+order.id" v-if="[STATUS_PENDING].includes(order.status)" class="btn btn-lg fs-6 btn-primary bg-gradient">
                                <BootstrapIcon color="fill-gray-100 text-gray-100" class="me-3" name="pencil-square" :size="20" />
                                <span class="align-middle">{{$t('requisition.edit_order')}}</span>
                            </router-link>
                            <!-- DISCARD ORDER -->
                            <button v-if="[STATUS_PENDING].includes(order.status)" @click="request_confirm_discard_order(order)" type="button" class="btn btn-lg fs-6 btn-orange-300 bg-gradient">
                                <BootstrapIcon color="fill-gray-900 text-gray-900" class="me-3" name="file-earmark-minus" :size="20" />
                                <span class="align-middle">{{$t('delivery.discard')}}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { createApp, defineComponent } from 'vue'
import BootstrapIcon from '@/common/directive/BootstrapIcon'
import TemplatePrintA8 from '@/modules/store/template/print_a8.vue'
import DateFormat from 'date-fns/format'
import { enUS, ro } from 'date-fns/locale'
import InventoryOrderStore from '@/common/store/inventory_order.js'

export default {
    props: {
        filters_search: {
            type: String,
            default: ''
        },
        orderIndex: {
            type: String,
            default: ''
        },
        order: {
            type: Object,
            default: () => {}
        },
        business_details: {
            type: Object,
            default: () => {}
        }
    },
    components: {
        BootstrapIcon
    },
    data: function () {
        return {
            loading: false,
            STATUS_PENDING: 'order_pending',
            STATUS_PREPARING: 'order_preparing',
            STATUS_COMPLETED: 'order_completed',
            STATUS_DELIVERED: 'order_delivered',
            STATUS_DISCARDED: 'order_discarded'
        }
    },
    computed: {
        locale: function () {
            return this.$i18n.locale
        }
    },
    methods: {
        format_date: function (timestamp) {
            return DateFormat(new Date(timestamp), 'dd MMM yyyy', { locale: this.locale === 'ro' ? ro : enUS })
        },
        update_delivery: async function (order_id, status) {
            this.loading = true
            const q = {
                id: order_id,
                params: {
                    status
                }
            }
            if (status === this.STATUS_DISCARDED) {
                q.params.reason = this.discard_reason
            }
            const response = await InventoryOrderStore.dispatch('UPDATE_DELIVERY', q)
            if (response.status === 0) {
                this.$bus.$emit('Notification/show', { title: 'Operation Successful!', msg: 'The changes have been saved!', level: 'success' })
                this.$bus.$emit('Order/status_handed')
            } else {
                this.$bus.$emit('Notification/show', { title: 'Something Went Wrong', msg: 'Please retry the operation or refresh the page. If the problem persists, please contact us via email.', level: 'danger' })
            }
            this.loading = false
        },
        print_a8: async function (order) {
            const self = this
            const component = {
                extends: TemplatePrintA8,
                data: function () {
                    return {
                        type: 'requisition',
                        order,
                        created_at: self.format_date(order.created_at, 'dd MMM yyyy HH:MM'),
                        business_details: { ...self.business_details }
                    }
                },
                methods: {
                    $t: self.$t
                }
            }
            const instance = defineComponent(component)
            const a = window.open('', '', 'height=500, width=500')
            const div = a.document.createElement('div')
            const app = createApp(instance).mount(div)

            a.document.write(`<html><body>${app.$el.outerHTML}</body></html>`)
            a.document.close()
            a.print()
        },
        request_confirm_email_receipt: async function (order) {
            this.$bus.$emit('Order/email_receipt', order)
        },
        request_confirm_discard_order: function (order) {
            this.$bus.$emit('Order/discard_order', order)
        }
    }
}
</script>
