import { createApp } from 'vue'
import $bus from '@/common/event.js'
import App from './App.vue'
import '@/registerServiceWorker'
import router from './router.js'
import { setupI18n, setI18nLanguage } from './i18n.js'
import make_store from '@/pages/store/store'
import en from '@/locale/store/en.json'

import 'bootstrap/js/dist/button'
import 'bootstrap/js/dist/tab'
import 'bootstrap/js/dist/dropdown'
import 'bootstrap/js/dist/collapse'
import modal from '@/common/modal/modal.vue'
import { SENTRY_DSN_STORE } from '@/common/constants.js'

import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import PrettyDate from '@/common/filter/PrettyDate.js'
import TimeAgo from '@/common/filter/TimeAgo.js'

const store = make_store($bus)
const app = createApp(App)

app.component('Modal', modal)

app.config.globalProperties.$bus = $bus
app.config.globalProperties.$filters = {
    PrettyDate,
    TimeAgo
}

const i18n = setupI18n({
    globalInjection: true,
    legacy: false,
    locale: 'en',
    fallbackLocale: 'en',
    messages: {
        en
    }
})

$bus.$on('App/locale', async function (locale) {
    setI18nLanguage(i18n, locale)
    store.commit('SET_LOCALE', locale)
})

if (store.state.locale !== 'en') {
    setI18nLanguage(i18n, store.state.locale)
}

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        Vue: app,
        dsn: SENTRY_DSN_STORE,
        integrations: [new Integrations.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracingOrigins: ['localhost', 'store.tapgiant.com', 'store.tapgiant.ro', /^\//]
        })],
        disablePerformance: true
    })
}

app.use(store)
    .use(router)
    .use(i18n)
    .mount('#app')
