<template>
    <div style="width: 100%; text-align: center; font-size: 13px;">
        <!-- Business Details -->
        <div v-if="business_details">
            <p v-for="(value, key) in business_details" :key="key" style="margin: 0px;">
                <!-- Workplace value has priority if available -->
                <span v-if="key === 'address' && typeof order.location.address === 'string' && order.location.address.length > 0">{{order.location.address}}</span>
                <span v-else-if="key === 'phone' && typeof order.location.phone === 'string' && order.location.phone.length > 0">{{order.location.phone}}</span>
                <span v-else>{{value}}</span>
            </p>
        </div>

        <!-- Order Properties -->
        <h5 style="margin: 10px 0px;">Comanda nr. {{order.id.split('-')[0].toUpperCase()}}</h5>
        <table style="border-top: 1px solid #444444; border-collapse: collapse; width: 100%;">
            <tbody>
                <tr>
                    <th style="text-align: center; border-top: 1px solid #444444; border-collapse: collapse; padding: 0px 2px; font-size: 12px;">Punct de lucru</th>
                    <td style="text-align: center; border-top: 1px solid #444444; border-collapse: collapse; padding: 0px 2px;">{{order.location.name}}</td>
                </tr>
                <tr v-if="type === 'delivery'">
                    <th style="text-align: center; border-top: 1px solid #444444; border-collapse: collapse; padding: 0px 2px; font-size: 12px;">Plasată de</th>
                    <td style="text-align: center; border-top: 1px solid #444444; border-collapse: collapse; padding: 0px 2px;">{{order.requisition.username}}</td>
                </tr>
                <tr>
                    <th style="text-align: center; border-top: 1px solid #444444; border-collapse: collapse; padding: 0px 2px; font-size: 12px;">Dată</th>
                    <td style="text-align: center; border-top: 1px solid #444444; border-collapse: collapse; padding: 0px 2px;">{{created_at}}</td>
                </tr>
            </tbody>
            <!-- Order Data -->
            <tbody v-if="order.data && Object.keys(order.data).length > 0">
                <tr v-for="(value, key) in order.data" :key="key">
                    <th style="text-align: center; border-top: 1px solid #444444; border-collapse: collapse; padding: 0px 2px; font-size: 12px;">{{$t('requisition.'+key)}}</th>
                    <td style="text-align: center; border-top: 1px solid #444444; border-collapse: collapse; padding: 0px 2px;"><strong>{{value}}</strong></td>
                </tr>
            </tbody>
        </table>

        <!-- Products -->
        <h5 style="margin: 10px 0px;">Produse</h5>
        <table style="border-top: 1px solid #444444; border-collapse: collapse; width: 100%; text-align: center;">
            <thead>
                <th>Produs</th>
                <th># /um</th>
            </thead>
            <tbody v-for="(product, key) in order.items" :key="key">
                <tr style="text-align: center; border-top: 1px solid #444444; border-collapse: collapse; padding: 0px 2px;">
                    <td>{{product.name}}</td>
                    <td>{{product._pivot_quantity}} {{$t('units.unit_abbr_'+product.unit)}}</td>
                </tr>
                <tr v-if="product._pivot_data && product._pivot_data.fields && Object.keys(product._pivot_data.fields).length > 0" style="text-align: center; border-top: 1px solid #444444; border-collapse: collapse; padding: 0px 10px;">
                    <td colspan="2" style="padding: 0px; border: 1px solid #444444;">
                        <div v-for="(set, skey) in product._pivot_data.fields" :key="skey" style="border: 1px solid #444444; width: 100%; padding: 2px; box-sizing: border-box;">
                            <span v-for="(field, fkey) in set" :key="fkey" style="margin-right: 5px;">
                                <strong style="margin-right: 2px;">{{field.name}}:</strong>
                                <span v-if="field.type === 'checkbox'">
                                    <span v-if="field.value instanceof Array">{{field.value.join(',')}}</span>
                                    <span v-else>{{field.value}}</span>
                                </span>
                                <span v-else>{{field.value}}</span>
                            </span>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>

        <!-- Customer -->
        <div v-if="order.customer_id && Object.keys(order.customer).length > 0" style="width: 100%;">
            <h5 style="margin: 10px 0px;">Detalii Client</h5>
            <table style="border-top: 1px solid #444444; border-collapse: collapse; width: 100%;">
                <tbody>
                    <tr v-if="order.customer.name">
                        <th style="text-align: center; border-top: 1px solid #444444; border-collapse: collapse; padding: 0px 2px;">{{$t('delivery.name')}}</th>
                        <td style="text-align: center; border-top: 1px solid #444444; border-collapse: collapse; padding: 0px 2px;">{{order.customer.name}}</td>
                    </tr>
                    <tr v-if="order.customer.phone">
                        <th style="text-align: center; border-top: 1px solid #444444; border-collapse: collapse; padding: 0px 2px;">{{$t('delivery.phone')}}</th>
                        <td style="text-align: center; border-top: 1px solid #444444; border-collapse: collapse; padding: 0px 2px;">{{order.customer.phone}}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <p style="text-align: center; font-size: 11px;">Vă mulțumim pentru comandă!</p>
    </div>
</template>
