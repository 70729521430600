<template>
    <div class="form-connect">
        <header role="banner" class="text-center">
            <router-link to="/" class="brand-logo">
                <img src="/img/identity/tgt_ro.png" alt="logo">
            </router-link>
        </header>

        <div class="p-4 shadow-sm bg-white rounded">
            <div>
                <h4 class="text-gray-700">{{$t('delivery.member_login')}}</h4>
                <div class="text-gray-600 fs-6">
                    <p>{{$t('delivery.create_and_process_orders')}}</p>
                </div>
            </div>
            <form @submit.prevent="submit" novalidate>
                <div class="form-group mt-3 has-validation">
                    <label class="form-label" for="pin">{{$t('delivery.username')}}</label>
                    <BootstrapIcon v-if="valid_username && error_code === -1" class="d-inline-block float-end" color="text-teal-500" name="check-circle" :size="16" />
                    <BootstrapIcon v-else class="d-inline-block float-end" color="text-gray-600" name="x-circle" :size="16" />
                    <input ref="username" :class="{'is-invalid': [401].includes(error_code) || error_code !== -1}" v-model="username" type="text" class="form-control form-control-lg" id="username">
                </div>
                <div v-if="recent_members.length > 0" class="mt-3">
                    <p>{{$t('delivery.recent_sessions')}}</p>
                    <div v-for="(member, index) in recent_members" :key="index" class="mb-2">
                        <div @click="username = member.u" class="bg-gray-200 border border-gray-300 shadow-sm rounded px-4 py-2">
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="rounded-circle overflow-hidden">
                                    <!-- <img :src="'data:image/png;base64,'+member.avatar" /> -->
                                </div>
                                <div class="flex-fill text-center fs-5">{{member.u}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group mt-3 has-validation">
                    <label class="form-label" for="pin">{{$t('delivery.pin_code')}}</label>
                    <BootstrapIcon v-if="valid_pin && error_code === -1" class="d-inline-block float-end" color="text-teal-500" name="check-circle" :size="16" />
                    <BootstrapIcon v-else class="d-inline-block float-end" color="text-gray-600" name="x-circle" :size="16" />
                    <input ref="pin" :class="{'is-invalid': [401].includes(error_code) || error_code !== -1}" v-model="pin" type="number" class="form-control form-control-lg" id="pin" autocomplete="off">
                    <div class="invalid-feedback text-center mt-2">
                        <span v-if="error_code === 401">{{$t('delivery.invalid_credentials')}}</span>
                    </div>
                </div>
                <div class="form-group mt-5">
                    <div class="d-grid gap-2">
                        <button :disabled="!valid_pin || loading" class="btn btn-lg btn-primary btn-block bg-teal-500 border-teal-600" type="submit">{{$t('delivery.login')}}</button>
                    </div>
                </div>
                <div class="text-gray-600 mt-3 text-center px-2">
                    <p class="fs-6">{{$t('delivery.by_submitting_you_agree_to')}} <router-link to="privacy" class="text-teal-500">{{$t('delivery.privacy_policy')}}</router-link> &amp; <router-link to="terms" class="text-teal-500">{{$t('delivery.terms_conditions')}}</router-link>.</p>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import BootstrapIcon from '@/common/directive/BootstrapIcon'
import Cookies from 'js-cookie'
import { nextTick } from 'vue'
import {
    DOMAIN,
    COOKIE_SECURE,
    COOKIE_RECENT_MEMBERS,
    COOKIE_RECENT_MEMBERS_VALIDITY_S
} from '@/common/constants.js'

export default {
    components: {
        BootstrapIcon
    },
    data: function () {
        return {
            pin: '',
            username: '',
            loading: false,
            error_code: -1,
            recent_members: []
        }
    },
    computed: {
        valid_pin: function () {
            return this.pin.toString().length >= 3
        },
        valid_username: function () {
            return this.username.length >= 3
        },
        is_authorized_member: function () {
            return this.$store.state.is_authorized_member
        }
    },
    watch: {
        pin: function () {
            this.error_code = -1
        },
        username: function () {
            this.error_code = -1
        }
    },
    created: async function () {
        if (this.is_authorized_member) {
            // redirect to delivery/requisition or remove cookie if no longer valid
            await this.get_member_info()
        }

        this.recent_members = this.get_recent_members().reverse()
    },
    mounted: async function () {
        const _self = this
        nextTick(function () {
            _self.$refs.username.focus()
        })
    },
    methods: {
        get_member_info: async function () {
            const response = await this.$store.dispatch('GET_MEMBER_INFO')
            if (response.status === 200) {
                if (response.data.status === 0) {
                    if (response.data.member.role === 'delivery') {
                        this.$router.replace('/delivery')
                    } else {
                        this.$router.replace('/requisition')
                    }
                }
            } else if (response.status === 401) {
                await this.$store.dispatch('LOGOUT_MEMBER')
            }
        },
        submit: async function () {
            this.loading = true
            const q = {
                username: this.username.toLowerCase(),
                pin: this.pin
            }
            const response = await this.$store.dispatch('LOGIN_MEMBER', q)
            if (response.status === 200) {
                this.set_recent_members(this.username)
                this.pin = ''
                this.username = ''
                this.$router.replace({
                    name: response.data.role === 'sales' ? 'requisition_index' : 'delivery_index'
                }).catch(() => {})
            } else {
                this.error_code = response.data.error_code
            }
            this.loading = false
        },
        set_recent_members: async function (username) {
            const m_index = this.recent_members.findIndex((m) => m.u === username)

            // add member
            if (m_index === -1) {
                this.recent_members.push({
                    u: username,
                    t: Date.now()
                })

            // update timestamp
            } else {
                this.recent_members[m_index].t = Date.now()
            }

            Cookies.set(COOKIE_RECENT_MEMBERS, JSON.stringify(this.recent_members), {
                expires: COOKIE_RECENT_MEMBERS_VALIDITY_S / 3600 / 24,
                secure: COOKIE_SECURE,
                domain: DOMAIN,
                path: '/',
                sameSite: 'strict'
            })
        },
        get_recent_members: function () {
            let _recent_members = Cookies.get(COOKIE_RECENT_MEMBERS)

            // nothing to parse
            if (!_recent_members) {
                return []
            }

            try {
                _recent_members = JSON.parse(_recent_members)
                if (_recent_members instanceof Array) {
                    // splice old or invalid entries
                    for (let m=0; m<_recent_members.length; ++m) {
                        if (typeof _recent_members[m] !== 'object' ||
                            typeof _recent_members[m].u !== 'string' ||
                            typeof _recent_members[m].t !== 'number' ||
                            Date.now - _recent_members[m].t > 15 * 24 * 3600 * 1000) {
                            _recent_members.splice(m, 1)
                            m--
                        }
                    }
                }

                Cookies.set(COOKIE_RECENT_MEMBERS, JSON.stringify(_recent_members), {
                    expires: COOKIE_RECENT_MEMBERS_VALIDITY_S / 3600 / 24,
                    secure: COOKIE_SECURE,
                    domain: DOMAIN,
                    path: '/',
                    sameSite: 'strict'
                })
            } catch {
                Cookies.remove(COOKIE_RECENT_MEMBERS, { path: '/', domain: DOMAIN })
                return []
            }

            return _recent_members
        }
    }
}
</script>
