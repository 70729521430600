<template>
    <div class="container">
        <div class="row mb-4">
            <div class="col">
                <div class="d-flex gap-3 flex-wrap justify-content-between">
                    <div @click="filter.category_id = ''" :class="{'bg-teal-500 text-white': filter.category_id === ''}" class="d-inline-block cursor-pointer noselect shadow-sm rounded py-2 px-3 bg-gray-300 bg-gradient flex-fill text-center text-nowrap">
                        {{$t('requisition.all_products')}}
                    </div>
                    <div @click="filter.category_id = category.id" v-for="(category, index) in categories" :key="index" :class="{'bg-teal-500 text-white': category.id === filter.category_id}" class="d-inline-block flex-fill text-center cursor-pointer noselect shadow-sm rounded py-2 px-3 bg-gray-300 text-nowrap">
                        {{category.name}} ({{category.items_count}})
                    </div>
                </div>
            </div>
        </div>

        <div class="row mb-4">
            <div class="col">
                <div class="input-group mb-3">
                    <input v-model="filter.search" type="text" class="form-control form-control-lg" :placeholder="$t('requisition.search_product')">
                    <button @click="filter.search = ''" class="btn btn-outline-secondary" type="button">{{$t('requisition.clear_search')}}</button>
                    <!-- <button @click="get_info(true)" :disabled="loading" type="button" class="btn btn-outline-secondary">Sincronizare Produse</button> -->
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col col-sm-6 mb-4">
                <ul v-if="filter_items.length > 0" class="list-group shadow-sm">
                    <button type="button" @click="add_provision(item)" v-for="(item, index) in filter_items" :key="index" class="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
                        <span class="fs-5">{{item.name}}</span>
                        <BootstrapIcon class="d-inline-block float-end" color="text-gray-600" name="plus-square" :size="24" />
                    </button>
                </ul>
                <div v-else>
                    <EmptyResponse :description="$t('requisition.no_product_found')" />
                </div>
            </div>

            <div class="col col-sm-6 mb-4">
                <div v-if="provisions.length === 0">
                    <EmptyShoppingCart :description="$t('requisition.click_products_add_to_order')" />
                </div>
                <div v-else class="position-relative">
                    <div v-if="order_id" class="d-flex justify-content-between align-items-center bg-white rounded border border-gray-500 mb-4 px-3 py-2">
                        <p class="m-0">{{$t('requisition.updating_order', { ORDER_ID: order_id.split('-')[0].toUpperCase() })}}</p>
                        <div>
                            <button @click="reset_form()" class="btn btn-warning fs-5" type="button">{{$t('requisition.cancel')}}</button>
                        </div>
                    </div>
                    <form @submit="submit_order" novalidate="true">
                        <!-- Item List -->
                        <ul class="list-group mb-4">
                            <li v-for="(item, index) in provisions" :key="index" class="list-group-item">
                                <div class="d-flex justify-content-between align-items-center">
                                    <span class="fs-5 me-2">{{item.name}}</span>
                                    <div class="d-inline float-end">
                                        <div class="input-group flex-nowrap">
                                            <button @click.stop.prevent="decrement_quantity($event, item)" class="btn btn-outline-secondary btn-lg" type="button">-</button>
                                            <input v-model="item.quantity" type="number" class="form-control" :class="{'bg-red-100': !is_numeric(item.quantity)}" style="width: 60px;">
                                            <span class="input-group-text">{{$t('units.unit_abbr_'+item.unit)}}</span>
                                            <button @click.stop.prevent="increment_quantity($event, item)" class="btn btn-outline-secondary btn-lg" type="button">+</button>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="item.form && item.form.fields && item.form.fields.length > 0" class="mt-2">
                                    <div v-for="(set, setIndex) in item.form.fields" :key="setIndex" class="border rounded px-3 py-2 mb-3 bg-gray-100">
                                        <div class="d-flex justify-content-between">
                                            <p class="small">{{$t('requisition.details_section')}} #{{setIndex+1}} - <span class="fw-bolder">{{item.name}}</span></p>
                                            <span @click="item.form.fields.splice(setIndex, 1)" class="text-blue-500 px-2 py-2 pe-pointer">{{$t('requisition.remove_section')}}</span>
                                        </div>
                                        <div v-for="(field, index2) in set" :key="index2">
                                            <div class="mb-3">
                                                <label :for="'item-field-'+setIndex+index+index2" class="form-label">{{field.name}}<span v-if="field.required" class="ms-1 text-red-500">*</span></label>
                                                <!-- Display either text or textarea -->
                                                <input v-if="field.type === 'text'" v-model="field.value" :required="field.required" type="text" class="form-control form-control-lg" :id="'item-field-'+setIndex+index+index2">
                                                <textarea v-else-if="field.type === 'textarea'" v-model="field.value" :required="field.required" rows="3" class="form-control" :id="'item-field-'+setIndex+index+index2"></textarea>
                                                <div v-else-if="field.type === 'radio'" class="dropdown">
                                                    <div v-for="(opt, index3) in field.options" :key="index3" class="form-check">
                                                        <input v-model="field.value" :value="opt" class="form-check-input" type="radio" :id="'item-radio-'+setIndex+index+index2+index3">
                                                        <label class="form-check-label" :for="'item-radio-'+setIndex+index+index2+index3">
                                                            {{opt}}
                                                        </label>
                                                    </div>
                                                </div>
                                                <div v-else-if="field.type === 'checkbox'">
                                                    <div v-for="(opt, index3) in field.options" :key="index3" class="form-check">
                                                        <input v-model="field.value" :value="opt" class="form-check-input" type="checkbox" :id="'item-checkbox-'+setIndex+index+index2+index3" :name="'item-checkbox-'+setIndex+index+index2">
                                                        <label class="form-check-label" :for="'item-checkbox-'+setIndex+index+index2+index3">
                                                            {{opt}}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="item.form && item.form.fields && item.quantity > item.form.fields.length" class="text-end mt-2 mb-1">
                                    <button @click="add_provision_form_fields(item, true)" type="button" class="btn btn-outline-secondary">+ {{$t('requisition.product_details')}} ({{item.form.fields.length}}/{{item.quantity}})</button>
                                </div>
                            </li>
                        </ul>

                        <!-- Observations -->
                        <div v-if="settings.observations.display" class="bg-white shadow-sm rounded mb-3 px-3 py-2">
                            <div class="mb-3">
                                <label for="observations" class="form-label">{{$t('requisition.observations')}} <CharCounter v-if="typeof form_data.observations === 'string'" :chars="form_data.observations.length" :max="500" /><span v-if="settings.observations.required" class="ms-1 text-red-500">*</span></label>
                                <textarea v-model="form_data.observations" :required="settings.observations.required" rows="3" class="form-control" id="observations"></textarea>
                            </div>
                        </div>

                        <!-- Custom Order Fields -->
                        <div v-if="customer_attach && Object.keys(settings).length > 0" class="bg-white shadow-sm rounded mb-3 px-3 py-2">
                            <div v-if="settings.customer_details.display">
                                <div class="mb-3">
                                    <label for="customer-details" class="form-label">{{$t('requisition.customer_details')}} <CharCounter v-if="typeof customer.name === 'string'" :chars="customer.name.length" :max="50" /><span v-if="settings.customer_details.required" class="ms-1 text-red-500">*</span></label>
                                    <input v-model="customer.name" :required="settings.customer_details.required" type="text" class="form-control form-control-lg" id="customer-details">
                                </div>
                            </div>
                            <div v-if="settings.customer_phone.display">
                                <div class="mb-3">
                                    <label for="customer-phone" class="form-label">{{$t('requisition.customer_phone')}} <CharCounter v-if="typeof customer.phone === 'string'" :chars="customer.phone.length" :max="50" /><span v-if="settings.customer_phone.required" class="ms-1 text-red-500">*</span></label>
                                    <input v-model="customer.phone" :required="settings.customer_phone.required" type="text" class="form-control form-control-lg" id="customer-phone">
                                </div>
                            </div>
                            <div v-if="settings.customer_email.display">
                                <div class="mb-3">
                                    <label for="customer-phone" class="form-label">{{$t('requisition.customer_email')}} <CharCounter v-if="typeof customer.email === 'string'" :chars="customer.email.length" :max="50" /><span v-if="settings.customer_email.required" class="ms-1 text-red-500">*</span></label>
                                    <input v-model="customer.email" :required="settings.customer_email.required" type="text" class="form-control form-control-lg" id="customer-phone">
                                </div>
                            </div>
                            <div v-if="settings.customer_address.display">
                                <div class="mb-3">
                                    <label for="customer-phone" class="form-label">{{$t('requisition.customer_address')}} <CharCounter v-if="typeof customer.address === 'string'" :chars="customer.address.length" :max="50" /><span v-if="settings.customer_address.required" class="ms-1 text-red-500">*</span></label>
                                    <input v-model="customer.address" :required="settings.customer_address.required" type="text" class="form-control form-control-lg" id="customer-phone">
                                </div>
                            </div>
                            <div v-if="settings.pickup_date.display || settings.pickup_hour.display" class="row">
                                <div class="col">
                                    <div v-if="settings.pickup_date.display">
                                        <div class="mb-3">
                                            <label for="pickup-date" class="form-label">{{$t('requisition.pickup_date')}}<span v-if="settings.pickup_date.required" class="ms-1 text-red-500">*</span></label>
                                            <input v-model="form_data.pickup_date" :required="settings.pickup_date.required" type="date" class="form-control form-control-lg" id="pickup-hour">
                                            <!-- <DatePicker /> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div v-if="settings.pickup_hour.display">
                                        <div class="mb-3">
                                            <label for="pickup-hour" class="form-label">{{$t('requisition.pickup_hour')}} (07:00 AM - 10:00 PM)<span v-if="settings.pickup_hour.required" class="ms-1 text-red-500">*</span></label>
                                            <input v-model="form_data.pickup_hour" :required="settings.pickup_hour.required" type="time" min="07:00" max="22:00" class="form-control form-control-lg" id="pickup-hour">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="settings.upfront_payment.display">
                                <div class="mb-3">
                                    <label for="upfront-payment" class="form-label">{{$t('requisition.upfront_payment')}} <CharCounter v-if="typeof form_data.upfront_payment === 'string'" :chars="form_data.upfront_payment.length" :max="50" /><span v-if="settings.upfront_payment.required" class="ms-1 text-red-500">*</span></label>
                                    <input v-model="form_data.upfront_payment" :required="settings.upfront_payment.required" type="text" class="form-control form-control-lg" id="upfront-payment">
                                </div>
                            </div>
                        </div>

                        <!-- FORM DATA ERRORS (ORDER FIELDS) -->
                        <div v-if="form_data_errors.length > 0" class="bg-white shadow-sm rounded border border-red-500 px-3 py-3 mb-3">
                            <p>{{$t('requisition.complete_following_information')}}:</p>
                            <ul class="mb-0">
                                <li v-for="(error, index) in form_data_errors" :key="index">{{error}}</li>
                            </ul>
                        </div>

                        <!-- ITEM DATA ERRORS (ITEM FIELDS) -->
                        <div v-if="item_data_errors.length > 0" class="bg-white shadow-sm rounded border border-red-500 px-3 py-3 mb-3">
                            <p>{{$t('requisition.complete_following_product_information')}}:</p>
                            <ul class="mb-0">
                                <li v-for="(error, index) in item_data_errors" :key="index">{{error}}</li>
                            </ul>
                            <p class="mb-0 mt-2">{{$t('requisition.no_complete_use_remove_section')}}.</p>
                        </div>

                        <!-- Attach Customer -->
                        <!-- Only display if customer or order details are enabled -->
                        <div v-if="settings_available > 0" class="d-grid pt-3 mb-3">
                            <button v-if="!customer_attach" @click="customer_attach = true" type="button" class="btn btn-secondary btn-lg">
                                <BootstrapIcon color="fill-gray-100 text-gray-100" class="me-3" name="person-lines-fill" :size="20" />
                                <span class="align-middle">{{$t('requisition.add_customer_details')}}</span>
                            </button>
                            <!-- Don't allow removal of customer details if edit order is in progress  -->
                            <button v-else-if="!customer.id" @click="customer_attach = false" type="button" class="btn btn-secondary btn-lg">{{$t('requisition.remove_customer_details')}}</button>
                        </div>

                        <!-- Submit Order Input -->
                        <div class="d-grid pt-3">
                            <button v-if="!order_id" type="submit" class="btn btn-success btn-lg">{{$t('requisition.submit_order')}}</button>
                            <button v-else :disabled="!order_changed" type="submit" class="btn btn-success btn-lg">
                                <span v-if="order_changed">{{$t('requisition.update_order')}}</span>
                                <span v-else>{{$t('requisition.nothing_to_update')}}</span>
                            </button>
                            <p class="py-2 text-center" v-html="$t('requisition.order_placed_for_location', { WORKPLACE: '<strong>'+location.name+'</strong>' })"></p>
                        </div>
                    </form>
                </div>

                <!-- Location Information -->
                <p class="text-center my-3 py-3">
                    <BootstrapIcon class="d-inline-block mx-2" color="text-gray-800 fill-gray-800" name="shop" :size="28" /><br>
                    <span class="fw-bolder fs-6 text-gray-800">{{location.name}}</span>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import CharCounter from '@/common/directive/CharCounter.vue'
import EmptyShoppingCart from '@/common/directive/EmptyShoppingCart.vue'
import EmptyResponse from '@/common/directive/EmptyResponse.vue'
import BootstrapIcon from '@/common/directive/BootstrapIcon'
import RequisitionStore from '@/common/store/requisition.js'
import InventoryOrderStore from '@/common/store/inventory_order.js'
import { REGEX_EMAIL } from '@/common/constants.js'

export default {
    components: {
        CharCounter,
        BootstrapIcon,
        EmptyResponse,
        EmptyShoppingCart
    },
    data: function () {
        return {
            STATUS_PENDING: 'order_pending',
            loading: false,
            provisions: [],
            form_data_errors: [],
            item_data_errors: [],
            filter: {
                category_id: '',
                search: ''
            },
            form_data: {
                pickup_date: '',
                pickup_hour: '',
                upfront_payment: '',
                observations: ''
            },
            settings_available: 0,
            customer_attach: false,
            customer: {
                id: '',
                name: '',
                address: '',
                phone: '',
                email: '',
                agree_call: false,
                agree_sms: false,
                agree_email: false
            },
            order_id: '',
            temp_str: {
                customer: '',
                form_data: '',
                provisions: ''
            }
        }
    },
    computed: {
        items: function () {
            return RequisitionStore.state.items
        },
        filter_items: function () {
            const self = this
            let filtered = this.items
            if (this.filter.category_id !== '') {
                filtered = filtered.filter(function (item) {
                    return item.category_id === self.filter.category_id
                })
            }
            if (this.filter.search !== '') {
                filtered = filtered.filter(function (item) {
                    return item.name.toLowerCase().includes(self.filter.search.toLowerCase())
                })
            }
            return filtered
        },
        categories: function () {
            return RequisitionStore.state.categories
        },
        locations: function () {
            return RequisitionStore.state.locations
        },
        settings: function () {
            return RequisitionStore.state.settings
        },
        location: function () {
            return RequisitionStore.state.location
        },
        order_changed: function () {
            return this.temp_str.customer !== JSON.stringify(this.customer) ||
                this.temp_str.provisions !== JSON.stringify(this.provisions) ||
                this.temp_str.form_data !== JSON.stringify(this.form_data)
        }
    },
    watch: {
        settings: function () {
            this.count_customer_settings()
        }
    },
    created: async function () {
    },
    mounted: async function () {
        this.count_customer_settings()

        if (/^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i.test(this.$route.query.order_id)) {
            await this.get_order(this.$route.query.order_id)
        }
    },
    methods: {
        get_order: async function (order_id) {
            this.loading = true
            const self = this
            const res = await InventoryOrderStore.dispatch('GET_ORDER', order_id)
            if (res.status === 200) {
                // Don't allow edit of orders that are not in pending
                if (res.data.order.status !== this.STATUS_PENDING) {
                    return
                }

                this.order_id = res.data.order.id

                // Handle Customer Data
                if (typeof res.data.order.customer_id === 'string' && typeof res.data.order.customer === 'object') {
                    this.customer = res.data.order.customer
                    this.customer_attach = true
                }

                // Handle Order Data
                if (typeof res.data.order.pickup_date === 'string') {
                    this.form_data.pickup_date = new Date(res.data.order.pickup_date).toISOString().substr(0, 10)
                    this.form_data.pickup_hour = new Date(res.data.order.pickup_date).toISOString().substr(11, 5)
                    this.customer_attach = true
                }
                this.form_data.upfront_payment = res.data.order.data.upfront_payment
                this.form_data.observations = res.data.order.data.observations

                // Handle Products in Order
                res.data.order.items.forEach(function (p) {
                    const _item = self.items.find((i) => i.id === p.id)
                    // Handle item not found? To check archived items
                    const _product = {
                        id: p.id,
                        name: p.name,
                        unit: p.unit,
                        category_id: _item.category_id,
                        // Must exist to permit comparison between item in EDIT order vs item in NEW order
                        data: {
                            fields: []
                        },
                        form: {
                            fields: []
                        },
                        quantity: p._pivot_quantity
                    }

                    // data column might be null - requires extra checks
                    if (typeof p._pivot_data !== 'undefined' && p._pivot_data !== null) {
                        _product.data.fields = _item.data.fields
                        _product.form.fields = p._pivot_data.fields
                    }
                    self.add_provision(_product)
                })

                // For later use in comparison
                this.temp_str.form_data = JSON.stringify(this.form_data)
                this.temp_str.customer = JSON.stringify(this.customer)
                this.temp_str.provisions = JSON.stringify(this.provisions)
            } else {
                this.$bus.$emit('Notification/show', { title: 'Something Went Wrong', msg: 'Please retry the operation or refresh the page. If the problem persists, please contact us via email.', level: 'danger' })
            }
            this.loading = false
        },
        is_numeric: function (str) {
            if (typeof str !== 'string' && typeof str !== 'number') {
                return false
            }
            return !isNaN(str) && !isNaN(parseFloat(str))
        },
        count_customer_settings: function () {
            this.settings_available = 0
            for (const s in this.settings) {
                // don't count settings that are not related to a customer order
                if (['observations', 'business_details'].includes(s)) {
                    continue
                }
                if (this.settings[s].display) {
                    this.settings_available++
                }
            }
        },
        valid_settings: function () {
            this.form_data_errors = []

            if (this.customer_attach && this.settings.customer_details && this.settings.customer_details.display && this.settings.customer_details.required && (typeof this.customer.name !== 'string' || this.customer.name.length === 0)) {
                this.form_data_errors.push(this.$t('requisition.customer_details'))
            }
            if (this.customer_attach && this.settings.customer_phone && this.settings.customer_phone.display && this.settings.customer_phone.required && (typeof this.customer.phone !== 'string' || this.customer.phone.length === 0)) {
                this.form_data_errors.push(this.$t('requisition.customer_phone'))
            }
            if (this.customer_attach && this.settings.customer_email && this.settings.customer_email.display && this.settings.customer_email.required && !REGEX_EMAIL.test(this.customer.email)) {
                this.form_data_errors.push(this.$t('requisition.customer_email'))
            }
            if (this.customer_attach && this.settings.customer_address && this.settings.customer_address.display && this.settings.customer_address.required && (typeof this.customer.address !== 'string' || this.customer.address.length === 0)) {
                this.form_data_errors.push(this.$t('requisition.customer_address'))
            }
            if (this.customer_attach && this.settings.pickup_date && this.settings.pickup_date.display && this.settings.pickup_date.required && (typeof this.form_data.pickup_date !== 'string' || this.form_data.pickup_date.length === 0)) {
                this.form_data_errors.push(this.$t('requisition.pickup_date'))
            }
            if (this.customer_attach && this.settings.pickup_hour && this.settings.pickup_hour.display && this.settings.pickup_hour.required && (typeof this.form_data.pickup_hour !== 'string' || this.form_data.pickup_hour.length === 0)) {
                this.form_data_errors.push(this.$t('requisition.pickup_hour'))
            }
            if (this.customer_attach && this.settings.upfront_payment && this.settings.upfront_payment.display && this.settings.upfront_payment.required && (typeof this.form_data.upfront_payment !== 'string' || this.form_data.upfront_payment.length === 0)) {
                this.form_data_errors.push(this.$t('requisition.upfront_payment'))
            }
            if (this.settings.observations && this.settings.observations.display && this.settings.observations.required && (typeof this.form_data.observations !== 'string' || this.form_data.observations.length === 0)) {
                this.form_data_errors.push(this.$t('requisition.observations'))
            }

            return this.form_data_errors.length === 0
        },
        valid_item_fields: function () {
            const self = this
            this.item_data_errors = []

            this.provisions.forEach(function (item) {
                if (!item.form || !item.form.fields) {
                    return
                }

                item.form.fields.forEach(function (set) {
                    set.forEach(function (field) {
                        if (field.required) {
                            if ((typeof field.value === 'string' && field.value.length === 0) ||
                                (field.value instanceof Array && field.value.length === 0)) {
                                self.item_data_errors.push(`${item.name} - ${field.name}`)
                            }
                        }
                    })
                })
            })

            return this.item_data_errors.length === 0
        },
        submit_order: async function (event) {
            event.preventDefault()

            if (!this.valid_settings() || !this.valid_item_fields()) {
                return
            }

            if (this.order_id) {
                await this.update_order()
            } else {
                await this.create_order()
            }
        },
        update_order: async function () {
            const q = {
                id: this.order_id,
                params: {}
            }

            // Prepare only modified data
            if (this.temp_str.customer !== JSON.stringify(this.customer)) {
                q.params.customer = {}
                // Only attach properties that have been filled
                for (const f in this.customer) {
                    if (typeof this.customer[f] === 'string' && this.customer[f].length > 0) {
                        q.params.customer[f] = this.customer[f]
                    }
                }
            }

            if (this.temp_str.provisions !== JSON.stringify(this.provisions)) {
                q.params.order = []
                this.provisions.forEach(function (item) {
                    const d = {
                        item_id: item.id,
                        quantity: item.quantity
                    }
                    if (item.form && item.form.fields && item.form.fields.length > 0) {
                        d.data = item.form
                    }
                    q.params.order.push(d)
                })
            }

            if (this.temp_str.form_data !== JSON.stringify(this.form_data)) {
                q.params.data = {}
                for (const d in this.form_data) {
                    if (typeof this.form_data[d] === 'string' && this.form_data[d].length > 0) {
                        q.params.data[d] = this.form_data[d]
                    }
                }
            }

            // If nothing to write => return
            if (Object.keys(q.params).length === 0) {
                return
            }

            const res = await InventoryOrderStore.dispatch('UPDATE_ORDER', q)
            if (res.status === 200) {
                if (res.data.status === 0) {
                    this.$bus.$emit('Notification/show', { title: this.$t('notifications.order_updated'), msg: this.$t('notifications.order_created_description'), level: 'success' })
                    this.reset_form()
                }
            } else if (res.status === 404) {
                this.$bus.$emit('Notification/show', { title: this.$t('notifications.validation_error'), msg: this.$t('notifications.validation_error_description'), level: 'danger' })
            } else if (res.status === 403) {
                this.$bus.$emit('Notification/show', { title: this.$t('notifications.validation_error'), msg: this.$t('notifications.validation_error_description'), level: 'danger' })
            } else {
                this.$bus.$emit('Notification/show', { title: this.$t('notifications.something_went_wrong'), msg: this.$t('notifications.please_retry_the_operation_or_refresh'), level: 'danger' })
            }
        },
        create_order: async function () {
            const q = {
                location_id: this.location.id,
                order: [],
                data: {}
            }

            for (const d in this.form_data) {
                if (typeof this.form_data[d] === 'string' && this.form_data[d].length > 0) {
                    q.data[d] = this.form_data[d]
                }
            }

            if (this.customer_attach) {
                q.customer = {}
                // Only attach properties that have been filled
                for (const f in this.customer) {
                    if (typeof this.customer[f] === 'string' && this.customer[f].length > 0) {
                        q.customer[f] = this.customer[f]
                    }
                }

                // Only attach boolean properties if any custom property added
                if (Object.keys(q.customer).length > 0) {
                    q.customer.agree_sms = this.customer.agree_sms
                    q.customer.agree_call = this.customer.agree_call
                    q.customer.agree_email = this.customer.agree_email
                } else {
                    delete q.customer
                }
            }

            this.provisions.forEach(function (item) {
                const d = {
                    item_id: item.id,
                    quantity: item.quantity
                }
                if (item.form && item.form.fields && item.form.fields.length > 0) {
                    d.data = item.form
                }
                q.order.push(d)
            })

            const response = await InventoryOrderStore.dispatch('CREATE', q)
            if (response.status === 200) {
                if (response.data.status === 0) {
                    this.$bus.$emit('Notification/show', { title: this.$t('notifications.order_created'), msg: this.$t('notifications.order_created_description'), level: 'success' })
                    this.reset_form()
                }
            } else if (response.status === 422) {
                this.$bus.$emit('Notification/show', { title: this.$t('notifications.validation_error'), msg: this.$t('notifications.validation_error_description'), level: 'danger' })
            } else {
                this.$bus.$emit('Notification/show', { title: this.$t('notifications.something_went_wrong'), msg: this.$t('notifications.please_retry_the_operation_or_refresh'), level: 'danger' })
            }
        },
        add_provision_form_fields: function (item, with_empty_values) {
            const set = []
            item.data.fields.forEach(function (field) {
                set.push({
                    name: field.name,
                    type: field.type,
                    required: field.required,
                    options: field.options,
                    // field.value might be defined when editing an existing order
                    value: typeof field.value !== 'undefined' && !with_empty_values ? field.value : field.type === 'checkbox' ? [] : ''
                })
            })
            item.form.fields.push(set)
        },
        add_provision: async function (item) {
            const index = this.provisions.findIndex(function (i) {
                return i.id === item.id
            })

            // Item already in "cart" => increment quantity
            if (index > -1) {
                this.provisions[index].quantity += 1
            } else {
                const clone = {
                    ...item,
                    // quantity might be defined when editing an existing order
                    quantity: typeof item.quantity === 'number' ? item.quantity : 1
                }

                // item.form might be defined when editing an existing order
                if (typeof item.form === 'object') {
                    clone.form = item.form

                // new product so populate first form.data.fields element
                } else if (item.data && item.data.fields && item.data.fields.length > 0) {
                    clone.form = {
                        fields: []
                    }
                    this.add_provision_form_fields(clone)
                }
                this.provisions.push(clone)
                this.provisions.sort(function (a, b) {
                    if (a.name < b.name) { return -1 }
                    if (a.name > b.name) { return 1 }
                    return 0
                })
            }
        },
        increment_quantity: async function ($event, item) {
            $event.stopPropagation()
            item.quantity++
        },
        decrement_quantity: async function ($event, item) {
            $event.stopPropagation()
            if (item.quantity <= 1) {
                const index = this.provisions.findIndex((i) => i.id === item.id)
                this.provisions.splice(index, 1)

                // Also reset form to prevent future edit of an order_id that was emptied
                if (this.provisions.length === 0) {
                    this.reset_form()
                }
            } else {
                item.quantity--

                // Only remove fieldset if there is an extra one added compared to the item quantity
                if (typeof item.form !== 'undefined' &&
                    (item.form.fields instanceof Array) &&
                    (item.form.fields.length > item.quantity)) {
                    item.form.fields.splice(item.form.fields.length-1, 1)
                }
            }
        },
        reset_form: async function () {
            this.$router.replace('/requisition')
            this.provisions = []
            this.form_data_errors = []
            this.item_data_errors = []
            this.form_data.pickup_date = ''
            this.form_data.pickup_hour = ''
            this.form_data.upfront_payment = ''
            this.form_data.observations = ''
            this.customer_attach = false
            this.customer.id = ''
            this.customer.name = ''
            this.customer.address = ''
            this.customer.phone = ''
            this.customer.email = ''
            this.customer.agree_call = false
            this.customer.agree_sms = false
            this.customer.agree_email = false
            this.order_id = ''
            this.temp_str.form_data = ''
            this.temp_str.provisions = ''
            this.temp_str.customer = ''
        }
    }
}
</script>
