<template>
    <div class="container">
        <div class="row mb-4">
            <div class="col">
                <div class="mb-2">
                    <router-link to="/delivery" class="px-2 btn btn-outline-secondary">
                        <BootstrapIcon color="fill-gray-600" name="chevron-left" :size="20" />
                        {{$t('delivery.all_orders')}}
                    </router-link>
                </div>
                <div class="d-flex flex-wrap gap-2 flex-fill justify-content-between">
                    <button v-if="order && order.id" @click="print_a4(order)" type="button" class="btn btn-teal-500 text-white bg-gradient flex-fill">
                        <BootstrapIcon color="fill-white text-white" class="me-3" name="printer" :size="20" />
                        <span class="align-middle">{{$t('requisition.print_a4')}}</span>
                    </button>
                    <button v-if="order && order.id" @click="print_a8(order)" type="button" class="btn btn-teal-500 text-white bg-gradient flex-fill">
                        <BootstrapIcon color="fill-white text-white" class="me-3" name="printer" :size="20" />
                        <span class="align-middle">{{$t('requisition.print_receipt')}}</span>
                    </button>
                </div>
            </div>
        </div>
        <div v-if="!loading && order && order.id" class="row">
            <div class="col">
                <div :class="{'bg-brown-100 border-brown-300': order.status === STATUS_DELIVERED, 'bg-orange-100 border-orange-300': order.status === STATUS_DISCARDED, 'bg-cyan-100 border-cyan-300': order.status === 'order_completed', 'bg-teal-100 border-teal-300': order.status === 'order_preparing', 'bg-red-100 border-red-400': order.status === 'order_pending'}" class="mb-3 py-2 px-3 shadow-sm bg-gradient rounded d-flex justify-content-between border">
                    <div>
                        <div v-if="order.status === STATUS_PENDING" class="flex-fill"><BootstrapIcon color="fill-coral-500 text-coral-500" class="me-3" name="circle-fill" :size="20" /><span class=align-middle>{{$t('delivery.pending')}}</span></div>
                        <div v-if="order.status === STATUS_PREPARING" class="flex-fill"><BootstrapIcon color="fill-teal-500 text-green-500" class="me-3" name="circle-fill" :size="20" /><span class=align-middle>{{$t('delivery.preparing')}}</span></div>
                        <div v-if="order.status === STATUS_COMPLETED" class="flex-fill"><BootstrapIcon color="fill-cyan-500 text-cyan-500" class="me-3" name="circle-fill" :size="20" /><span class=align-middle>{{$t('delivery.completed')}}</span></div>
                        <div v-if="order.status === STATUS_DISCARDED" class="flex-fill"><BootstrapIcon color="fill-orange-300 text-orange-300" class="me-3" name="circle-fill" :size="20" /><span class=align-middle>{{$t('delivery.discarded')}}</span></div>
                        <div v-if="order.status === STATUS_DELIVERED" class="flex-fill"><BootstrapIcon color="fill-brown-500 text-brown-500" class="me-3" name="circle-fill" :size="20" /><span class=align-middle>{{$t('delivery.handed')}} / {{format_date(order.updated_at, 'dd MMM yyyy')}}</span></div>
                    </div>
                    <div v-if="order.delivery" class="align-middle text-end">
                        <BootstrapIcon v-if="order.status === 'order_completed'" color="fill-cyan-500 text-cyan-500" class="me-3" name="cart-check" :size="26" />
                        <BootstrapIcon v-if="order.status === 'order_preparing'" color="fill-teal-500 text-teal-500" class="me-3" name="cart" :size="26" />
                        <BootstrapIcon v-if="order.status === STATUS_DISCARDED" color="fill-orange-300 text-orange-300" class="me-3" name="cart-x" :size="26" />
                        <span class="fw-bolder align-middle">{{order.delivery.username}}</span>
                    </div>
                </div>

                <div v-if="order.status === STATUS_DISCARDED" class="rounded bg-orange-100 border border-orange-300 px-3 py-2 mb-3">
                    <p class="m-0"><strong class="me-2">{{$t('delivery.discard_reason')}}:</strong>{{order.reason}}</p>
                </div>

                <div class="d-flex gap-2 flex-wrap justify-content-between mb-3">
                    <div class="d-inline-block shadow-sm rounded py-2 px-3 bg-white flex-fill text-center text-nowrap">
                        <BootstrapIcon class="me-2" color="fill-gray-600 text-gray-600" name="cart-plus" :size="22" />
                        <span class="align-middle">{{order.requisition.username}}</span>
                    </div>
                    <div class="d-inline-block shadow-sm rounded py-2 px-3 bg-white flex-fill text-center text-nowrap">
                        <BootstrapIcon class="me-2" color="fill-gray-600 text-gray-600" name="clock" :size="22" />
                        <span class="align-middle">{{format_date(order.created_at, 'dd MMM yyyy')}}</span>
                    </div>
                    <div class="d-inline-block shadow-sm rounded py-2 px-3 bg-white flex-fill text-center text-nowrap">
                        <BootstrapIcon class="me-2" color="fill-gray-600 text-gray-600" name="hash" :size="22" />
                        <span class="align-middle">{{order.id.split('-')[0].toUpperCase()}}</span>
                    </div>
                    <div class="d-inline-block shadow-sm rounded py-2 px-3 bg-yellow-200 flex-fill text-center text-nowrap border border-yellow-700">
                        <BootstrapIcon class="me-2" color="text-yellow-700 fill-yellow-700" name="geo-alt" :size="22" />
                        <span class="align-middle text-yellow-700 fw-bolder">{{order.location.name}}</span>
                    </div>
                </div>

                <!-- ORDER ITEMS -->
                <div v-for="(item, index) in order.items" :key="index">
                    <DeliveryItem :order_status="order.status" :_item="item" />
                </div>

                <!-- ORDER DATA -->
                <div v-if="order.data && Object.keys(order.data).length > 0" class="bg-white rounded shadow-sm mb-2">
                    <div class="bg-gray-500 px-3 py-2 rounded-top text-white">
                        <BootstrapIcon class="me-2" color="text-gray-100 fill-gray-100" name="file-text" :size="22" />
                        <span class="align-middle">{{$t('delivery.order_details')}}</span>
                    </div>
                    <div class="px-3 py-2">
                        <div v-for="(value, key) in order.data" :key="key">
                            <div><span class="fw-bolder">{{$t('requisition.'+key)}}:</span> {{value}}</div>
                        </div>
                    </div>
                </div>

                <!-- ORDER CUSTOMER -->
                <div v-if="order.customer_id && order.customer" class="bg-white rounded shadow-sm mb-2">
                    <div class="bg-gray-500 px-3 py-2 rounded-top text-white">
                        <BootstrapIcon class="me-2" color="text-gray-100 fill-gray-100" name="person-lines-fill" :size="22" />
                        <span class="align-middle">{{$t('delivery.customer')}}</span>
                    </div>
                    <div class="px-3 py-2">
                        <div v-if="order.customer.name && order.customer.name.length > 0"><span class="fw-bolder">{{$t('delivery.name')}}:</span> {{order.customer.name}}</div>
                        <div v-if="order.customer.phone && order.customer.phone.length > 0"><span class="fw-bolder">{{$t('delivery.phone')}}:</span> {{order.customer.phone}}</div>
                        <div v-if="order.customer.address && order.customer.address.length > 0"><span class="fw-bolder">{{$t('delivery.address')}}:</span> {{order.customer.address}}</div>
                    </div>
                </div>

                <!-- ORDER ACTIONS -->
                <div class="d-flex gap-2 justify-content-between flex-fill mt-4">
                    <button @click="request_confirm_discard_order()" :disabled="loading" v-if="['order_pending'].includes(order.status)" type="button" class="flex-fill btn bg-gradient py-3 btn-orange-300">
                        <BootstrapIcon color="fill-gray-900 text-gray-900" class="me-3" name="file-earmark-minus" :size="20" />
                        <span class="align-middle">{{$t('delivery.discard')}}</span>
                    </button>
                    <button @click="update_delivery(STATUS_PENDING)" :disabled="loading" v-if="['order_preparing'].includes(order.status)" type="button" class="flex-fill btn bg-gradient py-3 btn-danger">{{$t('delivery.pending')}}</button>
                    <button @click="update_delivery(STATUS_PREPARING)" :disabled="loading" v-if="['order_pending'].includes(order.status)" type="button" class="flex-fill btn bg-gradient py-3 btn-success">{{$t('delivery.prepare')}}</button>
                    <button @click="update_delivery(STATUS_COMPLETED)" :disabled="loading" v-if="['order_preparing'].includes(order.status)" type="button" class="flex-fill btn bg-gradient py-3 btn-info">{{$t('delivery.complete')}}</button>
                    <button @click="update_delivery(STATUS_DELIVERED)" :disabled="loading" v-if="order.customer_id && order.customer && order.status === STATUS_COMPLETED" type="button" class="flex-fill btn bg-gradient py-3 btn-brown-500">{{$t('delivery.handed_to_customer')}}</button>
                </div>
            </div>
        </div>
        <div v-else>
            <EmptyResponse :description="$t('delivery.no_orders')" />
        </div>

        <ModalConfirmDiscardOrder :order="order" />
    </div>
</template>

<script>
import BootstrapIcon from '@/common/directive/BootstrapIcon.vue'
import InventoryOrderStore from '@/common/store/inventory_order.js'
import EmptyResponse from '@/common/directive/EmptyResponse.vue'
import DeliveryItem from '@/common/directive/DeliveryItem.vue'
import { REGEX_UUID } from '@/common/constants.js'
import DateFormat from 'date-fns/format'
import { enUS, ro } from 'date-fns/locale'
import { createApp, defineComponent } from 'vue'
import TemplatePrintA4 from '@/modules/store/template/print_a4.vue'
import TemplatePrintA8 from '@/modules/store/template/print_a8.vue'
import ModalConfirmDiscardOrder from '@/common/modal/modal-confirm_discard_order.vue'

export default {
    components: {
        BootstrapIcon,
        EmptyResponse,
        DeliveryItem,
        ModalConfirmDiscardOrder
    },
    data: function () {
        return {
            STATUS_PENDING: 'order_pending',
            STATUS_PREPARING: 'order_preparing',
            STATUS_COMPLETED: 'order_completed',
            STATUS_DISCARDED: 'order_discarded',
            STATUS_DELIVERED: 'order_delivered',
            order_id: null,
            loading: true,
            order: null,
            business_details: null
        }
    },
    computed: {
        locale: function () {
            return this.$i18n.locale
        }
    },
    created: async function () {
        const self = this
        if (!this.$route.query.id || !REGEX_UUID.test(this.$route.query.id)) {
            return this.$router.replace('/delivery')
        } else {
            this.order_id = this.$route.query.id
        }

        this.$bus.$off('Order/status_discarded')
        this.$bus.$on('Order/status_discarded', function () {
            self.get_order()
        })

        await this.get_order()
    },
    methods: {
        format_date: function (timestamp, format) {
            return DateFormat(new Date(timestamp), format, { locale: this.locale === 'ro' ? ro : enUS })
        },
        get_order: async function () {
            const response = await InventoryOrderStore.dispatch('GET_ORDER', this.order_id)
            if (response.data.status === 0) {
                this.order = response.data.order
                this.business_details = response.data.business_details
                this.loading = false
            } else {
                this.$bus.$emit('Notification/show', { title: 'Something Went Wrong', msg: 'Please retry the operation or refresh the page. If the problem persists, please contact us via email.', level: 'danger' })
            }
        },
        update_delivery: async function (status) {
            this.loading = true
            const q = {
                id: this.order_id,
                params: {
                    status
                }
            }
            const response = await InventoryOrderStore.dispatch('UPDATE_DELIVERY', q)
            if (response.status === 0) {
                this.$bus.$emit('Notification/show', { title: 'Operation Successful!', msg: 'The changes have been saved!', level: 'success' })
            } else {
                this.$bus.$emit('Notification/show', { title: 'Something Went Wrong', msg: 'Please retry the operation or refresh the page. If the problem persists, please contact us via email.', level: 'danger' })
            }
            await this.get_order()
            this.loading = false
        },
        print_a8: async function (order) {
            const self = this
            const component = {
                extends: TemplatePrintA8,
                data: function () {
                    return {
                        type: 'delivery',
                        order,
                        created_at: self.format_date(order.created_at, 'dd MMM yyyy HH:MM'),
                        business_details: { ...self.business_details }
                    }
                },
                methods: {
                    $t: self.$t
                }
            }
            const instance = defineComponent(component)
            const a = window.open('', '', 'height=500, width=500')
            const div = a.document.createElement('div')
            const app = createApp(instance).mount(div)

            a.document.write(`<html><body>${app.$el.outerHTML}</body></html>`)
            a.document.close()
            a.print()
        },
        print_a4: async function (order) {
            const self = this
            const component = {
                extends: TemplatePrintA4,
                data: function () {
                    return {
                        order,
                        created_at: self.format_date(order.created_at, 'dd MMM yyyy HH:MM'),
                        business_details: { ...self.business_details }
                    }
                },
                methods: {
                    $t: self.$t
                }
            }
            const instance = defineComponent(component)
            const a = window.open('', '', 'height=500, width=500')
            const div = a.document.createElement('div')
            const app = createApp(instance).mount(div)

            a.document.write(`<html><body>${app.$el.outerHTML}</body></html>`)
            a.document.close()
            a.print()
        },
        request_confirm_discard_order: function () {
            this.$bus.$emit('Modal/show/confirm-order-discard')
        }
    }
}
</script>
