<template>
    <div>
        <NavbarRequisition />
        <main v-if="!loading" style="margin-top: 80px;">
            <router-view></router-view>
        </main>
        <NotificationHandler />
        <ModalSelectLocation />
    </div>
</template>

<script>
import NavbarRequisition from '@/components/NavbarRequisition.vue'
import NotificationHandler from '@/components/NotificationHandler.vue'
import RequisitionStore from '@/common/store/requisition.js'
import ModalSelectLocation from '@/common/modal/modal-select_location.vue'

export default {
    components: {
        NavbarRequisition,
        NotificationHandler,
        ModalSelectLocation
    },
    data: function () {
        return {
            timeout: 0,
            loading: true
        }
    },
    computed: {
        is_authorized_member: function () {
            return this.$store.state.is_authorized_member
        },
        location: function () {
            return RequisitionStore.state.location
        }
    },
    watch: {
        is_authorized_member: async function () {
            if (!this.is_authorized_member) {
                this.$router.replace('/')
            }

            await this.get_member_info()
        }
    },
    created: async function () {
        if (!this.is_authorized_member) {
            return this.$router.replace('/')
        }

        this.$bus.$off('requisition/get_info')
        this.$bus.$on('requisition/get_info', async function () {
            await self.get_info()
        })

        await this.get_member_info()
    },
    methods: {
        get_member_info: async function () {
            clearTimeout(this.timeout)
            const self = this
            this.timeout = setTimeout(async function () {
                const response = await self.$store.dispatch('GET_MEMBER_INFO')
                if (response.status === 200) {
                    if (response.data.status === 0) {
                        if (response.data.member.role === 'delivery') {
                            return self.$router.replace('/delivery')
                        }

                        await self.get_info()
                        if (typeof self.location.id !== 'string') {
                            self.$bus.$emit('Modal/show/select-location')
                        }
                    }
                    self.loading = false
                } else if (response.status === 401) {
                    self.loading = false
                    await self.$store.dispatch('LOGOUT_MEMBER')
                    return self.$router.replace('/')
                }
            }, 50)
        },
        get_info: async function () {
            await RequisitionStore.dispatch('GET_INFO')
        }
    }
}
</script>
