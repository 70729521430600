<template>
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="d-grid mb-4">
                    <button @click="get_orders(true)" :disabled="loading" type="button" class="btn py-3 bg-gradient btn-primary">{{$t('delivery.sync_orders')}}</button>
                </div>
            </div>
        </div>
        <div class="row mt-2 mb-4">
            <div class="col-12">
                <form @submit="submit">
                    <div class="input-group">
                        <input v-model="filters.search" style="min-width: 240px;" type="text" class="form-control fs-5" :placeholder="$t('requisition.search_by_fields')" :aria-label="$t('requisition.search_by_fields')" aria-describedby="button-addon2">
                        <button @click="filters.search = ''" class="btn btn-outline-secondary px-3" type="button" id="button-addon2">X</button>
                    </div>
                </form>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div v-if="locations.length > 0">
                    <div v-for="(location, indexL) in locations" :key="indexL" class="mb-4">
                        <div class="text-yellow-700 border border-yellow-700 bg-yellow-200 bg-gradient rounded shadow-sm d-flex justify-content-between py-2 px-3">
                            <div class="fw-bolder">
                                <BootstrapIcon class="d-inline-block me-3" color="text-yellow-700 fill-yellow-700" name="geo-alt" :size="20" />
                                <span class="align-middle">{{location.name}}</span>
                            </div>
                            <div v-if="location.orders.length === 1">1 {{$t('delivery.order')}}</div>
                            <div v-else>{{location.orders.length}} {{$t('delivery.orders')}}</div>
                        </div>
                        <div v-for="(order, index) in location.orders" :key="index" class="mt-2">
                            <router-link :to="'/delivery/order?id='+order.id" class="d-block bg-white shadow-sm rounded p-2 text-gray-700 text-decoration-none noselect mb-2">
                                <div class="d-flex justify-content-between align-items-center position-relative">
                                    <div v-if="order.status === 'order_pending'" class="mx-2 flex-fill"><BootstrapIcon color="fill-coral-500 text-coral-500" class="me-3" name="circle-fill" :size="20" /><span class=align-middle>{{$t('delivery.pending')}}</span></div>
                                    <div v-if="order.status === 'order_preparing'" class="mx-2 flex-fill"><BootstrapIcon color="fill-teal-500 text-teal-500" class="me-3" name="circle-fill" :size="20" /><span class=align-middle>{{$t('delivery.preparing')}}</span></div>
                                    <div v-if="order.status === 'order_completed'" class="mx-2 flex-fill"><BootstrapIcon color="fill-cyan-500 text-cyan-500" class="me-3" name="circle-fill" :size="20" /><span class=align-middle>{{$t('delivery.completed')}}</span></div>
                                    <div v-if="order.status === STATUS_DISCARDED" class="mx-2 flex-fill"><BootstrapIcon color="fill-orange-300 text-orange-300" class="me-3" name="circle-fill" :size="20" /><span class=align-middle>{{$t('delivery.discarded')}}</span></div>
                                    <div v-if="order.status === STATUS_DELIVERED" class="mx-2 flex-fill d-flex">
                                        <BootstrapIcon color="fill-brown-500 text-brown-500" class="me-3" name="circle-fill" :size="20" />
                                        <div>
                                            <span class="d-block">{{$t('delivery.handed')}}</span>
                                            <span class="d-block small">{{format_date(order.updated_at)}}</span>
                                        </div>
                                    </div>

                                    <div class="mx-2"><BootstrapIcon color="fill-gray-600 text-gray-600" name="stack" :size="20" class="mx-2" /><span class="align-middle">{{order.items.length}}</span></div>
                                    <div class="mx-2" style="min-width: 60px;"><BootstrapIcon color="fill-gray-600 text-gray-600" class="me-2" name="clock" :size="20" /><span class="align-middle">{{$filters.TimeAgo(order.created_at)}}</span></div>
                                    <BootstrapIcon color="fill-gray-600" name="chevron-right" :size="22" />
                                </div>
                                <div class="text-start">
                                    <div v-if="order.delivery" class="align-middle">
                                        <BootstrapIcon v-if="order.status === STATUS_DISCARDED" color="fill-orange-300 text-orange-300" class="mx-2" name="cart-x" :size="20" />
                                        <BootstrapIcon v-else-if="order.status === 'order_preparing'" color="fill-teal-500 text-teal-500" class="mx-2" name="cart" :size="20" />
                                        <!-- Should be for order_completed -->
                                        <BootstrapIcon v-else color="fill-cyan-500 text-cyan-500" class="mx-2" name="cart-check" :size="20" />
                                        <span class="fw-bolder align-middle ms-2">{{order.delivery.username}}</span>
                                    </div>
                                    <div v-if="order.customer_id">
                                        <BootstrapIcon color="fill-gray-600 text-gray-600" name="person-lines-fill" :size="20" class="mx-2" />
                                        <span class="fw-bolder align-middle ms-2">{{order.customer.name || order.customer.email || order.customer.phone}}</span>
                                    </div>
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <EmptyResponse :description="$t('delivery.no_orders')" />
                </div>
            </div>
        </div>
        <!-- TODO CHECK CURRENT REGISTRATION -->
        <!-- <div v-if="has_service_worker && username === 'batman'" class="row mt-5">
            <div class="col">
                <div class="d-grid mb-4">
                    <button v-if="registration" @click="push_unsubscribe()" :disabled="loading" type="button" class="btn btn-lg btn-outline-secondary">Dezactivează Notificări</button>
                    <button v-else @click="push_subscribe()" :disabled="loading" type="button" class="btn btn-lg btn-outline-secondary">Activează Notificări</button>
                </div>
            </div>
        </div> -->
    </div>
</template>

<script>
import EmptyResponse from '@/common/directive/EmptyResponse.vue'
import BootstrapIcon from '@/common/directive/BootstrapIcon'
import InventoryOrderStore from '@/common/store/inventory_order.js'
import { VAPID_PUBLIC_KEY } from '@/common/constants.js'
import DateFormat from 'date-fns/format'
import { enUS, ro } from 'date-fns/locale'

export default {
    components: {
        BootstrapIcon,
        EmptyResponse
    },
    data: function () {
        return {
            STATUS_DISCARDED: 'order_discarded',
            STATUS_DELIVERED: 'order_delivered',
            loading: false,
            locations: [],
            filters: {
                search: ''
            },
            timeout: 0
        }
    },
    computed: {
        locale: function () {
            return this.$i18n.locale
        }
        // is_authorized_member: function () {
        //     return this.$store.state.is_authorized_member
        // },
        // username: function () {
        //     return this.$store.state.member_delivery.username
        // },
        // registration: function () {
        //     return this.$store.state.member_delivery.registration
        // },
        // has_service_worker: function () {
        //     return 'serviceWorker' in navigator
        // }
    },
    watch: {
        filters: {
            deep: true,
            handler: function () {
                clearTimeout(this.timeout)

                const self = this
                this.timeout = setTimeout(async function () {
                    await self.get_orders()
                }, 250)
            }
        }
        // is_authorized_member: function () {
        //     if (!this.is_authorized_member) {
        //         this.$router.replace('/')
        //     }
        // }
    },
    created: async function () {
        // if (!this.is_authorized_member) {
        //     this.$router.replace('/')
        // }

        await this.get_orders(false)
        // await this.get_push_subscription()
    },
    methods: {
        submit: async function ($event) {
            $event.preventDefault()
        },
        format_date: function (timestamp) {
            return DateFormat(new Date(timestamp), 'dd MMM yyyy', { locale: this.locale === 'ro' ? ro : enUS })
        },
        // get_push_subscription: async function () {
        //     try {
        //         const registration = await navigator.serviceWorker.getRegistration()
        //         if (registration.pushManager) {
        //             const subscription = await registration.pushManager.getSubscription()
        //             console.log(subscription)
        //         }
        //     } catch (e) {
        //     }
        // },
        push_subscribe: async function () {
            try {
                const registration = await navigator.serviceWorker.getRegistration()
                if (registration.pushManager) {
                    const subscription = await registration.pushManager.subscribe({
                        userVisibleOnly: true,
                        applicationServerKey: this.urlBase64ToUint8Array(VAPID_PUBLIC_KEY)
                    })
                    const response = await this.$store.dispatch('PUSH_SUBSCRIBE', { subscription })
                    if (response.status === 201 || response.status === 200) {
                        this.$bus.$emit('Notification/show', { title: 'Notificări Activate!', msg: 'Vei primi notificări la plasarea comenzilor', level: 'success' })
                    } else {
                        this.$bus.$emit('Notification/show', { title: 'Something Went Wrong', msg: 'Please retry the operation or refresh the page. If the problem persists, please contact us via email.', level: 'danger' })
                    }
                }
            } catch (e) {
                this.$bus.$emit('Notification/show', { title: 'Something Went Wrong', msg: 'Please retry the operation or refresh the page. If the problem persists, please contact us via email.', level: 'danger' })
            }
        },
        push_unsubscribe: async function () {
            // todo unsubscribe
        },
        urlBase64ToUint8Array: function (base64String) {
            const padding = '='.repeat((4 - base64String.length % 4) % 4)
            const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/')

            const rawData = window.atob(base64)
            const outputArray = new Uint8Array(rawData.length)

            for (let i = 0; i < rawData.length; ++i) {
                outputArray[i] = rawData.charCodeAt(i)
            }
            return outputArray
        },
        get_orders: async function (display_notification) {
            const query = {}
            if (this.filters.search.length >= 2) {
                query.search = this.filters.search
            }
            const response = await InventoryOrderStore.dispatch('GET_INFO', query)
            if (response.data.status === 0) {
                this.locations = response.data.locations
                if (display_notification) {
                    this.$bus.$emit('Notification/show', { title: 'Sincronizare efectuată', msg: 'Lista de comenzi a fost actualizată.', level: 'success' })
                }
            } else {
                this.$bus.$emit('Notification/show', { title: 'Something Went Wrong', msg: 'Please retry the operation or refresh the page. If the problem persists, please contact us via email.', level: 'danger' })
            }
        }
    }
}
</script>
