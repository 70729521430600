<template>
    <nav class="navbar navbar-expand-md navbar-light bg-white shadow-sm fixed-top">
        <div class="container">
            <div class="text-start navbar-brand">
                <img src="/img/identity/tgt_ro.png" alt="logo" height="30">
            </div>
            <button v-if="username && is_authorized_member" class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div v-if="username && is_authorized_member" class="collapse navbar-collapse text-center ms-4" id="navbarSupportedContent">
                <ul class="navbar-nav ms-auto w-100">
                    <li class="nav-item">
                        <router-link class="nav-link fs-5" exact-active-class="text-decoration-underline" to="/requisition" exact>{{$t('requisition.orders')}}</router-link>
                    </li>
                    <li v-if="settings && settings.pickup_date && settings.pickup_date.display" class="nav-item">
                        <router-link class="nav-link fs-5" exact-active-class="text-decoration-underline" to="/requisition/calendar" exact>{{$t('requisition.calendar')}}</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link fs-5" exact-active-class="text-decoration-underline" to="/requisition/history" exact>{{$t('requisition.history')}}</router-link>
                    </li>
                    <li v-if="locations.length > 1" class="nav-item dropdown ms-sm-auto">
                        <a class="nav-link dropdown-toggle me-4" href="#" id="dropdown04" data-bs-toggle="dropdown" aria-expanded="false">
                            <BootstrapIcon class="d-inline-block mx-2" color="text-gray-800 fill-gray-800" name="shop" :size="20" />
                            <strong>{{location.name}}</strong>
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="dropdown04">
                            <li v-for="(location, index) in locations" :key="index">
                                <a @click="set_location(location)" class="dropdown-item align-middle text-decoration-none">
                                    <span>{{location.name}}</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
                <div class="dropdown d-inline-block">
                    <button type="button" class="btn btn-outline-brand dropdown-toggle fw-bold" data-bs-toggle="dropdown" aria-expanded="false">
                        {{username}}
                    </button>
                    <ul class="dropdown-menu dropdown-menu-end">
                        <li>
                            <a @click="logout()" class="dropdown-item align-middle">
                                <BootstrapIcon class="align-middle d-inline-block me-3" color="fill-red-500 text-red-500" :name="'power'" :size="18" />
                                <span class="align-middle d-inline-block text-red-500">Logout</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
import BootstrapIcon from '@/common/directive/BootstrapIcon.vue'
import RequisitionStore from '@/common/store/requisition.js'

export default {
    components: {
        BootstrapIcon
    },
    computed: {
        username: function () {
            return this.$store.state.member.username
        },
        is_authorized_member: function () {
            return this.$store.state.is_authorized_member
        },
        locale: function () {
            return this.$i18n.locale
        },
        locations: function () {
            return RequisitionStore.state.locations
        },
        location: function () {
            return RequisitionStore.state.location
        },
        settings: function () {
            return RequisitionStore.state.settings
        }
    },
    methods: {
        logout: async function () {
            await this.$store.dispatch('LOGOUT_MEMBER')
        },
        set_locale: function (locale) {
            this.$bus.$emit('App/locale', locale)
        },
        set_location: function (location) {
            RequisitionStore.commit('SET_LOCATION', location)
        }
    }
}
</script>
