<template>
    <Modal :close_button="false" :size="'modal-sm'" mid="select-location">
        <template v-slot:header>
            <span>{{$t('requisition.select_workplace')}}</span>
        </template>
        <template v-slot:body>
            <div>
                <div v-for="(location, index) in locations" :key="index">
                    <div @click="set_location(location)" class="text-center py-3 pe-pointer noselect bg-gray-200 mb-2 rounded shadow-sm border border-gray-300 fs-5">{{location.name}}</div>
                </div>
            </div>
        </template>
    </Modal>
</template>

<script>
import RequisitionStore from '@/common/store/requisition.js'

export default {
    computed: {
        locations: function () {
            return RequisitionStore.state.locations
        },
        location: function () {
            return RequisitionStore.state.location
        }
    },
    methods: {
        set_location: function (location) {
            RequisitionStore.commit('SET_LOCATION', location)
            this.$bus.$emit('Modal/hide/select-location')
        }
    }
}
</script>
