import { createRouter, createWebHistory } from 'vue-router'

import LayoutPublicStore from '@/components/LayoutPublicStore.vue'
import LayoutRequisition from '@/components/LayoutRequisition.vue'
import LayoutDelivery from '@/components/LayoutDelivery.vue'

import StoreLogin from '@/modules/store/login.vue'

import Contact from '@/modules/public/static/contact.vue'
import PrivacyPolicy from '@/modules/public/static/privacy_policy.vue'
import TermsOfService from '@/modules/public/static/terms_of_service.vue'
import LegalEntity from '@/modules/public/static/legal.vue'
import Static404 from '@/modules/public/static/404.vue'

// Delivery Views
import DeliveryIndex from '@/modules/store/delivery/index.vue'
import DeliveryOrder from '@/modules/store/delivery/order.vue'

// Requisition Views
import RequisitionIndex from '@/modules/store/requisition/index.vue'
import RequisitionCalendar from '@/modules/store/requisition/calendar.vue'
import RequisitionHistory from '@/modules/store/requisition/history.vue'

const routes = [
    {
        path: '/',
        name: 'Tapgiant - Member Login',
        component: StoreLogin
    },
    {
        path: '/',
        component: LayoutPublicStore,
        children: [
            {
                path: '/contact',
                name: 'contact',
                component: Contact
            },
            {
                path: '/privacy',
                name: 'privacy',
                component: PrivacyPolicy
            },
            {
                path: '/terms',
                name: 'terms',
                component: TermsOfService
            },
            {
                path: '/legal',
                name: 'legal',
                component: LegalEntity
            }
        ]
    },
    {
        path: '/requisition',
        component: LayoutRequisition,
        children: [
            {
                path: '',
                name: 'requisition_index',
                component: RequisitionIndex
            },
            {
                path: '/requisition/history',
                name: 'requisition_history',
                component: RequisitionHistory
            },
            {
                path: '/requisition/calendar',
                name: 'requisition_calendar',
                component: RequisitionCalendar
            }
        ]
    },
    {
        path: '/delivery',
        component: LayoutDelivery,
        children: [
            {
                path: '',
                name: 'delivery_index',
                component: DeliveryIndex
            },
            {
                path: '/delivery/order',
                name: 'delivery_order',
                component: DeliveryOrder
            }
        ]
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/404'
    },
    {
        path: '/404',
        name: '404',
        component: Static404
    }
]

const router = createRouter({
    history: createWebHistory(),
    scrollBehavior: function (to, from, savedPosition) {
        if (to.hash) {
            return { el: to.hash }
        } else {
            return { x: 0, y: 0 }
        }
    },
    routes
})

export default router
