<template>
    <div>
        <!-- Business Details -->
        <div v-if="business_details">
            <p v-for="(value, key) in business_details" :key="key" style="margin: 0px;">
                <span v-if="key === 'address' && typeof order.location.address === 'string' && order.location.address.length > 0">{{order.location.address}}</span>
                <span v-else-if="key === 'phone' && typeof order.location.phone === 'string' && order.location.phone.length > 0">{{order.location.phone}}</span>
                <span v-else>{{value}}</span>
            </p>
        </div>

        <!-- Order Properties -->
        <h4 style="margin: 10px 0px;">Comanda nr. {{order.id.split('-')[0]}}</h4>
        <table style="border: 1px solid #444444; border-collapse: collapse;">
            <tbody>
                <tr>
                    <th style="text-align: center; border: 1px solid #444444; border-collapse: collapse; padding: 0px 10px; font-size: 12px;">Punct de lucru</th>
                    <td style="text-align: center; border: 1px solid #444444; border-collapse: collapse; padding: 0px 10px; font-size: 12px;">{{order.location.name}}</td>
                </tr>
                <tr>
                    <th style="text-align: center; border: 1px solid #444444; border-collapse: collapse; padding: 0px 10px; font-size: 12px;">Plasată de</th>
                    <td style="text-align: center; border: 1px solid #444444; border-collapse: collapse; padding: 0px 10px; font-size: 12px;">{{order.requisition.username}}</td>
                </tr>
                <tr>
                    <th style="text-align: center; border: 1px solid #444444; border-collapse: collapse; padding: 0px 10px; font-size: 12px;">Dată</th>
                    <td style="text-align: center; border: 1px solid #444444; border-collapse: collapse; padding: 0px 10px; font-size: 12px;">{{created_at}}</td>
                </tr>
            </tbody>
            <!-- Order Data -->
            <tbody v-if="order.data && Object.keys(order.data).length > 0">
                <tr v-for="(value, key) in order.data" :key="key">
                    <th style="text-align: center; border: 1px solid #444444; border-collapse: collapse; padding: 0px 10px; font-size: 12px;">{{$t('requisition.'+key)}}</th>
                    <td style="text-align: center; border: 1px solid #444444; border-collapse: collapse; padding: 0px 10px; font-size: 12px;">{{value}}</td>
                </tr>
            </tbody>
        </table>

        <!-- Customer -->
        <div v-if="order.customer_id && Object.keys(order.customer).length > 0">
            <h4 style="margin: 10px 0px;">Detalii Client</h4>
            <table style="border: 1px solid #444444; border-collapse: collapse;">
                <tbody>
                    <tr v-if="order.customer.name">
                        <th style="text-align: center; border: 1px solid #444444; border-collapse: collapse; padding: 0px 10px; font-size: 12px;">{{$t('delivery.name')}}</th>
                        <td style="text-align: center; border: 1px solid #444444; border-collapse: collapse; padding: 0px 10px; font-size: 12px;">{{order.customer.name}}</td>
                    </tr>
                    <tr v-if="order.customer.phone">
                        <th style="text-align: center; border: 1px solid #444444; border-collapse: collapse; padding: 0px 10px; font-size: 12px;">{{$t('delivery.phone')}}</th>
                        <td style="text-align: center; border: 1px solid #444444; border-collapse: collapse; padding: 0px 10px; font-size: 12px;">{{order.customer.phone}}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <!-- Products -->
        <h4 style="margin: 10px 0px;">Produse</h4>
        <table style="border: 1px solid #444444; border-collapse: collapse;">
            <tbody>
                <tr>
                    <th style="text-align: center; border: 1px solid #444444; border-collapse: collapse; padding: 0px 10px; font-size: 12px;">Produs</th>
                    <th style="text-align: center; border: 1px solid #444444; border-collapse: collapse; padding: 0px 10px; font-size: 12px;">Cantitate comandată</th>
                    <th style="text-align: center; border: 1px solid #444444; border-collapse: collapse; padding: 0px 10px; font-size: 12px;">Cantitate livrată</th>
                    <th style="text-align: center; border: 1px solid #444444; border-collapse: collapse; padding: 0px 10px; font-size: 12px;">Detalii</th>
                </tr>
                <tr v-for="(product, key) in order.items" :key="key">
                    <td style="text-align: center; border: 1px solid #444444; border-collapse: collapse; padding: 0px 10px; font-size: 12px;">{{product.name}}</td>
                    <td style="text-align: center; border: 1px solid #444444; border-collapse: collapse; padding: 0px 10px; font-size: 12px;">{{product._pivot_quantity}} {{$t('units.unit_abbr_'+product.unit)}}</td>
                    <td style="text-align: center; border: 1px solid #444444; border-collapse: collapse; padding: 0px 10px; font-size: 12px;">{{product._pivot_new_quantity}} {{$t('units.unit_abbr_'+product.unit)}}</td>
                    <td style="text-align: center; border: 1px solid #444444; border-collapse: collapse; padding: 0px 10px; font-size: 12px;">
                        <table v-if="product._pivot_data && product._pivot_data.fields && Object.keys(product._pivot_data.fields).length > 0" style="border: 1px solid #444444; border-collapse: collapse; margin: 5px 0px;">
                            <tbody v-for="(set, skey) in product._pivot_data.fields" :key="skey">
                                <tr v-for="(field, fkey) in set" :key="fkey" style="text-align: center; border: 1px solid #444444; border-collapse: collapse; padding: 0px 10px; font-size: 12px;">
                                    <th style="text-align: center; border: 1px solid #444444; border-collapse: collapse; padding: 0px 10px; font-size: 12px;">{{field.name}}</th>
                                    <td style="text-align: center; border: 1px solid #444444; border-collapse: collapse; padding: 0px 10px; font-size: 12px;">
                                        <span v-if="field.type === 'checkbox'">
                                            <span v-if="field.value instanceof Array">{{field.value.join(',')}}</span>
                                            <span v-else>{{field.value}}</span>
                                        </span>
                                        <span v-else>{{field.value}}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
