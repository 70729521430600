<template>
    <nav class="navbar navbar-expand-sm navbar-light bg-white shadow-sm fixed-top">
        <div class="container">
            <div class="me-auto navbar-brand">
                <img src="/img/identity/tgt_ro.png" alt="logo" height="30">
            </div>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse text-center navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav text-center ms-auto">
                    <li class="nav-item ms-lg-auto dropdown">
                        <a class="nav-link dropdown-toggle me-4" href="#" id="dropdown03" data-bs-toggle="dropdown" aria-expanded="false">
                            <span v-if="locale === 'ro'"><img src="/img/flag/ro.png" class="me-2">RO</span>
                            <span v-if="locale === 'en'"><img src="/img/flag/us.png" class="me-2">EN</span>
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="dropdown03">
                            <li><span v-if="locale !== 'ro'" @click="set_locale('ro')" class="pe-pointer mx-2 py-2 d-block"><img src="/img/flag/ro.png" class="me-2">RO</span></li>
                            <li><span v-if="locale !== 'en'" @click="set_locale('en')" class="pe-pointer mx-2 py-2 d-block"><img src="/img/flag/us.png" class="me-2">EN</span></li>
                        </ul>
                    </li>
                </ul>
                <div v-if="username" class="dropdown d-inline-block">
                    <button type="button" class="btn btn-sm fs-6 btn-outline-brand dropdown-toggle fw-bold" data-bs-toggle="dropdown" aria-expanded="false">
                        {{username}}
                    </button>
                    <ul class="dropdown-menu dropdown-menu-end">
                        <li>
                            <a @click="logout()" class="dropdown-item align-middle">
                                <BootstrapIcon class="align-middle d-inline-block me-3" color="fill-red-500 text-red-500" :name="'power'" :size="18" />
                                <span class="align-middle d-inline-block text-red-500">Logout</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
import BootstrapIcon from '@/common/directive/BootstrapIcon.vue'

export default {
    components: {
        BootstrapIcon
    },
    data: function () {
        return {
            info: {}
        }
    },
    computed: {
        username: function () {
            return this.$store.state.member.username
        },
        locale: function () {
            return this.$i18n.locale
        }
    },
    methods: {
        logout: async function () {
            await this.$store.dispatch('LOGOUT_MEMBER')
        },
        set_locale: function (locale) {
            this.$bus.$emit('App/locale', locale)
        }
    }
}
</script>
